import { LoadScript } from "@react-google-maps/api";
import { Outlet } from "react-router-dom";
import Navbar from "../components/Navbar/Navbar";
import Footer from "../components/Footer/Footer";
import ModalManager from "../features/modalManager/ModalManager";
import { APIProvider } from "@vis.gl/react-google-maps";
import { Library } from "@googlemaps/js-api-loader";
import BackToTop from "../components/BackToTop/BackToTop";
import useAppInitialization from "../hooks/initialization/useAppInitialization";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const mapsLibraries: Library[] = ["places", "drawing"];

const Layout = () => {
  let DEBUG = import.meta.env.VITE_DEBUG;

  useAppInitialization();

  // @ts-ignore
  return (
    <div className="flex min-h-screen flex-col justify-between bg-rf-very-light-gray">
      <Navbar />
      <main className="flex grow flex-col">
        {/* HACK: using 2 Google Maps libraries to enable location search and map view. */}
        <BackToTop />
        <LoadScript
          googleMapsApiKey="AIzaSyDyCUJKharkI2xm5SGbWb1glPk8xBp6VF0"
          libraries={mapsLibraries}
        >
          <APIProvider
            apiKey={"AIzaSyDyCUJKharkI2xm5SGbWb1glPk8xBp6VF0"}
            libraries={["places", "geometry"]}
          >
            <Outlet />
            <ToastContainer
              position="bottom-right"
              autoClose={5000}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              limit={3}
            />

            <ModalManager />
          </APIProvider>
        </LoadScript>
      </main>
      <Footer />
    </div>
  );
};

export default Layout;
