import React, { useState } from "react";
import ReactDOM from "react-dom/client";
import { createHashRouter, RouterProvider } from "react-router-dom";
import { initializeIcons } from "@fluentui/react";
import { store } from "./app/store";
import { Provider } from "react-redux";

import "./index.css";

import Layout from "./layouts/Layout";
import { MotionsContent } from "./features/RelevantMotions/components/MotionsContent";
import Auth from "./Auth";
import Account from "./account";
import { adminRoutes } from "./pages/admin/route";
import AddressSearch from "./pages/addressSearch/AddressSearch";
import Checklist from "./pages/user/checklist";
import TOS from "./pages/tos/TOS";
import DocumentViewer from "./pages/qa/DocumentViewer";
import DocumentRenderer from "./features/AnswerToUserQuestion/components/DocumentRenderer/DocumentRenderer";

const layout = <Layout />;

if (process.env.NODE_ENV === "production") {
  // @ts-ignore
  import("./instrument.js"); // Assuming instrument.js is in the same directory
}

initializeIcons();

const router = createHashRouter([
  {
    path: "/",
    element: layout,
    children: [
      {
        index: true,
        element: (
          <AddressSearch
            key={"address"}
            modules={["info", "permits", "motions", "chat"]}
          />
        ),
      },
      {
        path: "auth",
        element: <Auth />,
      },
      {
        path: "account",
        element: <Account />,
      },
      {
        path: "qa",
        element: (
          <AddressSearch
            key={"address"}
            modules={["info", "permits", "motions", "chat"]}
          />
        ),
      },
      {
        path: "/address",
        element: (
          <AddressSearch
            key={"address"}
            modules={["info", "permits", "motions", "chat"]}
          />
        ),
      },
      {
        path: "/parking",
        element: <AddressSearch modules={["parking"]} key={"parking"} />,
      },
      {
        path: "/permits",
        element: <AddressSearch modules={["permits"]} key={"permits"} />,
      },
      {
        path: "/signage",
        element: <AddressSearch modules={["signage"]} key={"signage"} />,
      },
      {
        path: "/setbacks",
        element: <AddressSearch modules={["setbacks"]} key={"setbacks"} />,
      },
      {
        path: "/info",
        element: <AddressSearch modules={["info"]} key={"info"} />,
      },
      {
        path: "/chat",
        // element: <Chat />
        element: <AddressSearch modules={["chat"]} key={"chat"} />,
      },
      {
        path: "/pdf",
        element: <DocumentRenderer />,
      },
      {
        path: "/document",
        element: <DocumentViewer />,
      },
      {
        path: "/motions",
        element: <MotionsContent />,
      },
      {
        path: "motions/:id",
        element: <MotionsContent />,
      },
      {
        path: "user/checklist",
        element: <Checklist />,
      },
      adminRoutes,
      {
        path: "tos",
        element: <TOS />,
      },
      {
        path: "crash",
        element: (
          <button
            className={"h-28 w-48 bg-red-600"}
            // @ts-ignore
            onClick={() => undef()}
          >
            Break the world
          </button>
        ),
      },
      {
        path: "*",
        lazy: () => import("./pages/NoPage"),
      },
    ],
  },
]);

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  </React.StrictMode>,
);
