import React from "react";
import { twMerge } from "tailwind-merge";
import TextSkeleton from "./TextSkeleton";

type Props = {
  className?: string;
};

export default function BarGraphSkeleton({ className }: Props) {
  return (
    <div
      role="status"
      className={twMerge(
        `flex h-full max-h-full max-w-full flex-col`,
        className,
      )}
    >
      <div
        role="status"
        className="flex grow animate-pulse flex-col rounded border border-rf-light-gray p-4 shadow md:p-6"
      >
        <div className="mt-4 flex grow items-baseline justify-between">
          <div className="h-[90%] w-[12%] rounded-t-lg bg-rf-light-gray sm:w-[8%]"></div>
          <div className="h-[75%] w-[12%] rounded-t-lg bg-rf-light-gray sm:w-[8%]"></div>
          <div className="h-[90%] w-[12%] rounded-t-lg bg-rf-light-gray sm:w-[8%]"></div>
          <div className="h-[85%] w-[12%] rounded-t-lg bg-rf-light-gray sm:w-[8%]"></div>
          <div className="h-[100%] w-[12%] rounded-t-lg bg-rf-light-gray sm:w-[8%]"></div>
          <div className="hidden h-[90%] w-[8%] rounded-t-lg bg-rf-light-gray sm:block"></div>
          <div className="hidden h-[100%] w-[8%] rounded-t-lg bg-rf-light-gray sm:block"></div>
        </div>
        <div className="mt-6">
          <TextSkeleton lines={2} lineWidth={60} />
        </div>
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
}
