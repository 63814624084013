import React from 'react';

interface JsonViewerProps {
  answers: any[];
}

export function JsonViewer({answers}: JsonViewerProps) {
  if (!answers || answers.length == 0) return null;

  return answers.map((answer, i) => (
    <div key={i} className="bg-gray-900 p-6 rounded-lg shadow-xl overflow-x-auto">
      <pre className="text-green-400 font-mono text-sm whitespace-pre-wrap break-words" style={{ wordWrap: 'break-word', maxWidth: '100%' }}>
            {JSON.stringify(answer, null, 2)}
          </pre>
    </div>
  ))
}
