import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { toggleVerticalBodyScroll } from "../../helpers/toggleVerticalBodyScroll";
import { getModalTitle } from "./getModalTitle";

export type ModalTypes =
  | "MotionsViewer"
  | "SearchError"
  | "SavedAddresses"
  | null;

interface InitialStateType {
  isOpen: boolean;
  modalType: ModalTypes;
  modalTitle?: string;
  modalProps: Record<string, any>;
}

const initialState: InitialStateType = {
  isOpen: false,
  modalType: null,
  modalProps: {},
  modalTitle: "",
};

const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    openModal: (
      state,
      action: PayloadAction<{
        modalType: ModalTypes;
        modalProps?: Record<string, any>;
        modalTitle?: string;
      }>,
    ) => {
      const newModalTitle = action.payload.modalTitle
        ? action.payload.modalTitle
        : getModalTitle(action.payload.modalType);

      state.isOpen = true;
      state.modalType = action.payload.modalType;
      state.modalProps = action.payload.modalProps || {};
      state.modalTitle = newModalTitle;

      toggleVerticalBodyScroll(true);
    },
    closeModal: (state) => {
      state.isOpen = false;
      state.modalType = null;
      state.modalProps = {};

      toggleVerticalBodyScroll(false);
    },
  },
});

export const { openModal, closeModal } = modalSlice.actions;

export default modalSlice.reducer;
