import { Link } from "react-router-dom";

import { Auth } from "@supabase/auth-ui-react";
import { ThemeSupa } from "@supabase/auth-ui-shared";
import { Dialog } from "@fluentui/react";
import { supabase } from "../../api/db";
import { useEffect, useState } from "react";
import { Person24Filled } from "@fluentui/react-icons";
import { useAppSelector } from "../../app/hooks";

export default function Navbar() {
  const { session } = useAppSelector((state) => state.user);
  const [firstName, setFirstName] = useState("");

  useEffect(
    function () {
      if (session?.user?.id) {
        supabase
          .from("users")
          .select("first_name")
          .eq("id", session.user.id) // This only matters for admins who get full visibility
          .single() // Expecting only one user with this ID
          .then(({ data: user, error }) => {
            // Destructure data and error
            if (error) {
              console.error("Error getting user:", error);
            } else if (user) {
              setFirstName(user.first_name);
            }
          });
      }
    },
    [session],
  );

  return (
    <header
      className={
        "z-10 flex h-[80px] flex-row items-center justify-between bg-rf-dark-brown px-9 text-white shadow-[0px_4px_4px_0px_rgba(0,0,0,0.25)]"
      }
      role={"banner"}
    >
      <div className={"block text-transparent sm:hidden"}>
        <Person24Filled />
      </div>

      <Link to="/" className={`header-font text-nowrap font-bold text-white`}>
        redflag
      </Link>

      {!session ? (
        <Dialog hidden={false}>
          <Auth
            providers={["google"]}
            supabaseClient={supabase}
            appearance={{ theme: ThemeSupa }}
          />
        </Dialog>
      ) : (
        // @ts-ignore
        <a href={"/#/account"} className={"inline text-nowrap"}>
          <span className={"hidden md:inline"}>Hello </span>
          <span className={"hidden sm:inline"}>
            {firstName ?? session?.user?.email ?? ""}
          </span>

          <div className={"block sm:hidden"}>
            <Person24Filled />
          </div>
        </a>
      )}
    </header>
  );
}
