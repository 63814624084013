import React from "react";
import TextArea from "../../../../components/forms/TextArea/TextArea";
import { useAppSelector } from "../../../../app/hooks";
import { UseFormSetValue } from "react-hook-form";
import type { AddressSearchFormType } from "../../AddressSearchSection";

type Props = {
  userPrompt: string | undefined;
  setValue: UseFormSetValue<AddressSearchFormType>;
};

export default function SearchQuestion({ setValue, userPrompt }: Props) {
  const { isLoading } = useAppSelector((state) => state.addressSearch);

  const handleUserPromptChange = (value: string) => {
    setValue("userPrompt", value);
  };

  return (
    <TextArea
      placeholder="Ask a question about this site"
      value={userPrompt ?? ""}
      onChange={handleUserPromptChange}
      disabled={isLoading}
      formSubmitOnEnter={true}
    />
  );
}
