import SearchInput from "./SearchInput";
import SearchQuestion from "./SearchQuestion";
import { UseFormSetValue } from "react-hook-form";
import type { AddressSearchFormType } from "../../AddressSearchSection";

type Props = {
  address: string | undefined;
  userPrompt: string | undefined;
  setValue: UseFormSetValue<AddressSearchFormType>;
};

export default function SearchForm({ address, userPrompt, setValue }: Props) {
  return (
    <>
      <SearchInput address={address} setValue={setValue} />
      <SearchQuestion userPrompt={userPrompt} setValue={setValue} />
    </>
  );
}
