import React, { useRef } from "react";
import { twMerge } from "tailwind-merge";
import { highlightMatch } from "../../../../helpers/highlightMatch";
import useClickOutside from "../../../../hooks/useClickOutside";
import useArrowKeyNavigation from "../../../../hooks/useArrowKeyNavigation";

interface Props {
  onPlaceSelect: (place: google.maps.places.AutocompletePrediction) => void;
  address: string | undefined;
  className?: string;
  isOpen: boolean;
  predictions: google.maps.places.AutocompletePrediction[];
  closeDropdown: () => void;
}
export default function SearchAddressAutocomplete({
  onPlaceSelect,
  address = "",
  className,
  isOpen,
  predictions,
  closeDropdown,
}: Props) {
  const dropdownRef = useRef<HTMLDivElement>(null);
  const predictionRefs = useRef<(HTMLButtonElement | null)[]>([]);

  // Close dropdown on outside click
  useClickOutside(dropdownRef, () => {
    setFocusedIndex(-1);
    closeDropdown();
  });

  // Handle keydown events for arrow navigation
  const { focusedIndex, setFocusedIndex } = useArrowKeyNavigation({
    isOpen,
    items: predictions ?? [],
    onSelect: onPlaceSelect,
  });

  if (!isOpen || predictions.length === 0 || predictions.length === 0)
    return <></>;

  return (
    <div
      ref={dropdownRef}
      className={twMerge("searchTextAreaDropdown", className)}
    >
      <p className="body-font border-t border-rf-light-gray px-[20px] pb-1 pt-2 font-semibold">
        Suggested Locations
      </p>

      <ul className="thin-scrollbar max-h-[400px] w-full overflow-y-auto">
        {predictions.map((prediction, index) => (
          <li key={prediction.place_id} className="w-full">
            <button
              ref={(el) => (predictionRefs.current[index] = el)} // Set ref to each button
              className={twMerge(
                "line-clamp-1 w-full cursor-pointer break-all rounded-md px-[20px] py-1 text-left duration-300 hover:bg-rf-light-gray",
                focusedIndex === index && "bg-rf-light-gray", // Highlight the focused item
              )}
              onMouseDown={() => {
                setFocusedIndex(-1);
                onPlaceSelect(prediction);
              }}
            >
              <p className="body-font mr-1.5 inline">
                {highlightMatch(
                  prediction.structured_formatting.main_text,
                  address,
                )}
              </p>
              <p className="citation-font inline">
                {prediction.structured_formatting.secondary_text}
              </p>
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
}
