import { closeModal } from "../modalSlice";
import { useAppDispatch } from "../../../app/hooks";

export default function SearchError() {
  const dispatch = useAppDispatch();
  const handleCloseModal = () => dispatch(closeModal());

  return (
    <div className="max-w-[600px] text-center">
      <p className="text-rf-vibrant-red">
        The address you entered is currently outside our supported search area,
        which only includes incorporated parts of Los Angeles.
      </p>

      <p className="mb-6 mt-3">
        We're continuously expanding our coverage. Future updates will include
        more areas.
      </p>

      <button
        className="w-full rounded bg-rf-red px-4 py-2 font-bold text-white duration-300 hover:bg-rf-light-red"
        onClick={handleCloseModal}
      >
        Close
      </button>
    </div>
  );
}
