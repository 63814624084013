import { AnalysisPanelTabs } from "../../components/AnalysisPanel";
import AddressSearchSection from "../../features/AddressSearchSection/AddressSearchSection";
import PlanningAndZoning from "../../features/PlanningAndZoning/PlanningAndZoning";
import PermittingTimeline from "../../features/PermittingTimeline/PermittingTimeline";
import AnswerToUserQuestion from "../../features/AnswerToUserQuestion/AnswerToUserQuestion";
import RelevantMotions from "../../features/RelevantMotions/RelevantMotions";
import Jurisdiction from "../../features/Jurisdiction/Jurisdiction";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  setEndpointOutputs,
  setActiveCitation,
  setActiveAnalysisPanelTab,
} from "../../features/AddressSearchSection/addressSearchSectionSlice";
import { twMerge } from "tailwind-merge";
import SideNav from "../../components/ui/SideNav/SideNav";
import ThreeColumnLayout from "../../layouts/ThreeColumnLayout";
import { selectHasEndpointData } from "../../features/AddressSearchSection/addressSearchSectionSlice";
import TextSkeleton from "../../components/ui/loaders/TextSkeleton";
import useFilterSideNavSections from "./hooks/useFilterSideNavSections";
import FixedSideNavPopover from "./components/FixedSideNavPopover/FixedSideNavPopover";

type AddressSearchModule =
  | "info"
  | "parking"
  | "setbacks"
  | "signage"
  | "permits"
  | "motions"
  | "chat";

interface Props {
  modules?: AddressSearchModule[];
}

const AddressSearch = (props: Props) => {
  const dispatch = useAppDispatch();
  const {
    endpointOutputs,
    isLoading,
    activeAnalysisPanelTab,
    activeCitation,
    hasReportBeenGenerated,
  } = useAppSelector((state) => state.addressSearch);

  const hasEndpointData = useAppSelector(selectHasEndpointData);

  const sideNavSections = useFilterSideNavSections();

  const modules: string[] | undefined = props.modules; // empty = all

  const leftColRender = isLoading ? (
    TextSkeleton({ lines: 4, lineWidth: 70 })
  ) : sideNavSections.length > 0 ? (
    <SideNav className="sticky top-6" sections={sideNavSections} />
  ) : (
    <></>
  );
  return (
    <div className={twMerge("mx-auto w-full")}>
      <AddressSearchSection modules={modules} />

      <ThreeColumnLayout
        leftCol={leftColRender}
        className={twMerge(
          "defaultPageStyle",
          (hasEndpointData || hasReportBeenGenerated || isLoading) &&
            "relative mb-3 mt-6 sm:mb-12 md:mt-[75px] lg:mt-[150px]",
        )}
      >
        <>
          <FixedSideNavPopover sections={sideNavSections} />
          <AnswerToUserQuestion />
          <PlanningAndZoning />
          <Jurisdiction />
          <PermittingTimeline />
          <RelevantMotions />{" "}
        </>
      </ThreeColumnLayout>
    </div>
  );
};

export default AddressSearch;
