import React from "react";

export default function TOS() {
  return (
    <div className="defaultPageStyle  flex h-full max-w-[800px] grow flex-col items-center justify-center">
      <h1 className="header-font mb-3 text-center">Terms and Conditions</h1>
      <p className={"body-font text-center"}>
        Redflag does not guarantee the accuracy or reliability of any
        information on this website. All content is provided "as is" and "as
        available," without any warranties. Redflag is not liable for any
        damages arising from the use of this website, including but not limited
        to errors, omissions, interruptions, or delays, regardless of whether
        the content originates from Redflag or other sources.
      </p>
    </div>
  );
}
