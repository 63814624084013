import React, { ComponentType, useRef } from "react";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { closeModal } from "./modalSlice";
import { Dismiss16Filled } from "@fluentui/react-icons";

// modals
import MotionsViewer from "./modals/MotionsViewer";
import SearchError from "./modals/SearchError";
import SavedAddresses from "./modals/SavedAddresses/SavedAddresses";

const MODAL_COMPONENTS: { [key: string]: ComponentType<any> } = {
  MotionsViewer,
  SearchError,
  SavedAddresses,
};

export default function ModalManager() {
  const dispatch = useAppDispatch();
  const { isOpen, modalType, modalProps, modalTitle } = useAppSelector(
    (state) => state.modal,
  );

  const modalRef = useRef<HTMLDivElement>(null); // Create a ref

  if (!isOpen || !modalType) return null;

  const SpecificModal = MODAL_COMPONENTS[modalType];

  const handleClickOutside = (
    evt: React.MouseEvent<HTMLDivElement> | MouseEvent,
  ) => {
    let event = evt as MouseEvent;
    if (
      modalRef.current &&
      !modalRef.current.contains(event.target as HTMLElement)
    ) {
      dispatch(closeModal());
    }
  };

  // @ts-ignore
  return (
    <div
      className="fixed inset-0 z-50 flex items-end justify-center bg-black bg-opacity-50 sm:items-center "
      onClick={handleClickOutside}
    >
      <div className="relative w-fit bg-white shadow-md rounded-t-lg sm:rounded-lg overflow-clip" ref={modalRef}>
        {/* Attach the ref to the modal content */}
        <div className="flex h-[40px] w-full items-center justify-between gap-3 bg-rf-very-light-gray pl-3 pr-1.5 text-left">
          <p className="body-font line-clamp-1 grow break-all font-bold">
            {modalTitle}
          </p>

          <button
            type="button"
            className="items-center bg-transparent p-1.5 text-sm text-rf-very-dark-gray duration-300 hover:text-opacity-75"
            onClick={() => dispatch(closeModal())}
          >
            <Dismiss16Filled />
            <span className="sr-only">Close modal</span>
          </button>
        </div>

        <div className="thin-scrollbar h-fit max-h-[calc(70vh)]  w-[100vw] min-w-[360px] max-w-[100vw] overflow-y-scroll p-6 pb-[160px] pl-6 pr-4 sm:h-full sm:max-h-[80vh] sm:max-w-[640px] sm:pb-6">
          <SpecificModal {...modalProps} />
        </div>
      </div>
    </div>
  );
}
