import ReportSectionSkeleton from "../../../components/ui/loaders/ReportSectionSkeleton";

import AccordionSkeleton from "../../../components/ui/loaders/AccordionSkeleton";
import TextSkeleton from "../../../components/ui/loaders/TextSkeleton";

export default function PermittingTimelineSkeleton() {
  return (
    <AccordionSkeleton title="Permitting Timeline">
      <>
        <ReportSectionSkeleton title>
          <TextSkeleton lines={4} columns={2} lineWidth={30} />
        </ReportSectionSkeleton>

        <ReportSectionSkeleton title></ReportSectionSkeleton>
        <ReportSectionSkeleton title></ReportSectionSkeleton>
        <ReportSectionSkeleton title></ReportSectionSkeleton>
      </>
    </AccordionSkeleton>
  );
}
