import React, { useRef, useState } from "react";
import { AnalysisPanelTabs } from "../../components/AnalysisPanel";
import SearchForm from "./components/SearchForm/SearchForm";
import OptionalModifiers from "./components/OptionalModifiers/OptionalModifiers";
import ReportFormDrawer from "./components/ReportFormDrawer";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  resetAddressSearchState,
  setLoading,
} from "../../features/AddressSearchSection/addressSearchSectionSlice";
import { useForm, SubmitHandler } from "react-hook-form";
import { setHasReportBeenGenerated } from "../../features/AddressSearchSection/addressSearchSectionSlice";
import SearchMap from "./components/SearchMap";
import useSubmitSearchQuery from "./hooks/useSubmitSearchQuery";
import SubmitButton from "./components/SubmitButton";
import { useSearchParams } from "react-router-dom";
import { useValidateAddress } from "./hooks/useValidateAddress";
import { AddressSearchInfo } from "../../helpers/constants";
import { toast } from "react-toastify";

type Props = {
  modules: string[] | undefined;
};

export type AddressSearchFormType = {
  //SearchForm
  address: string | undefined;
  coordinates: { lat: number; lng: number } | undefined;
  userPrompt: string | undefined;
  //OptionalModifiers
  zoningType?: string;
  permitSubType?: string[];
  programType?: string[];
  miscTypeSelected?: Set<string>;
};

const DEBUG: boolean | undefined = undefined;

//  todo : add initialize state, add error handling,

export default function AddressSearchSection({ modules }: Props) {
  const dispatch = useAppDispatch();

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors, isValid },
  } = useForm<AddressSearchFormType>();

  const { isLoading, validatedAddress, searchParams } = useValidateAddress({
    setValue,
  });

  const address = watch("address");
  const coordinates = watch("coordinates");
  const userPrompt = watch("userPrompt");
  const zoningType = watch("zoningType");
  const permitSubType = watch("permitSubType")?.[0];
  const programType = watch("programType")?.[0];
  const miscTypeSelected = watch("miscTypeSelected") || new Set();

  // idk why we need these
  const [isStreaming, setIsStreaming] = useState<boolean>(false);
  const [error, setError] = useState<unknown>();
  const lastQuestionRef = useRef<string>("");
  const lastLocationRef = useRef<string>("");
  // idk why we need these

  const [makeApiRequest] = useSubmitSearchQuery({
    setIsStreaming,
    setError,
    error,
    userPrompt,
    permitSubType,
    zoningType,
    programType,
    miscTypeSelected,
    modules,
    coordinates,
    lastLocationRef,
  });

  const onSubmit: SubmitHandler<AddressSearchFormType> = async (data) => {
    const parkingQuestion =
      "How many parking spaces are required for this location?";
    const setbackQuestion =
      "What are the setback requirements for this location";
    const signageQuestion =
      "What are the signage requirements for this location";

    const questionArray = [parkingQuestion, signageQuestion, setbackQuestion];
    const coordsArray = coordinates
      ? [coordinates.lat, coordinates.lng]
      : undefined;

    if (!address)
      return toast.warning("Invalid address", { toastId: "invalidAddress" });

    if (!coordinates)
      return toast.warning("Invalid coordinates", { toastId: "invalidCoords" });

    dispatch(setLoading(true));
    try {
      await makeApiRequest(questionArray, address, coordsArray);
      dispatch(setHasReportBeenGenerated(true));
    } catch (error) {
      console.error("Report generation failed:", error);
    } finally {
      dispatch(setLoading(false));
    }
  };

  return (
    <div
      key={searchParams ? validatedAddress : undefined}
      id={AddressSearchInfo.id}
      className="flex w-full bg-white shadow-[0px_4px_14px_0px_rgba(0,0,0,0.25)] landscape:min-h-[calc(100vh-120px)]"
    >
      <div className="flex-shrink-0">
        <ReportFormDrawer />
      </div>
      <div className="defaultPageStyle flex grow flex-col justify-start pb-12 pt-12 md:pt-20 lg:pt-28 xl:pt-36">
        {(!DEBUG || !lastQuestionRef.current) && (
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mx-auto flex w-full max-w-[1200px] flex-col items-center justify-between gap-6 lg:flex-row lg:items-start">
              <div className="flex w-full max-w-[462px] flex-col gap-3">
                <p className="header-font pb-4 text-center tracking-[-0.3px] lg:pb-8 lg:text-left">
                  redflag report generator
                </p>
                <SearchForm
                  setValue={setValue}
                  address={address}
                  userPrompt={userPrompt}
                />
                <SubmitButton coordinates={coordinates} address={address} />
              </div>

              <SearchMap isLoading={isLoading} coordinates={coordinates} />
            </div>
            <div className="mx-auto mt-6 max-w-[1200px] lg:mt-12 xl:mt-16">
              <OptionalModifiers
                zoningType={zoningType}
                coordinates={coordinates}
                address={address}
                permitSubType={permitSubType}
                programType={programType}
                miscTypeSelected={miscTypeSelected}
                setValue={setValue}
                register={register}
              />
            </div>
          </form>
        )}
      </div>
    </div>
  );
}
