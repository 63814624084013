// useAnnotations.js
import { useState, useEffect, useRef } from "react";
import { supabase } from "../../../api";
import { ParsedAnnotation } from "../../../components/Answer";
import type { IHighlight } from "react-pdf-highlighter";
import {RfHighlight} from "../../../components/HighlightDocContent/RfHighlight";

type Props = {
  citedSources: ParsedAnnotation[] | undefined;
  additionalSources: ParsedAnnotation[] | undefined;
  scrollToHighlightFromHash: () => void;
};

export const useAnnotations = ({
  citedSources,
  additionalSources,
  scrollToHighlightFromHash,
}: Props) => {
  const [url, setUrl] = useState<string>("");
  const [highlights, setHighlights] = useState<Array<Array<RfHighlight>>>([]);
  const references = useRef<{
    [key: string]: {
      filename: string;
      highlights: IHighlight[];
    };
  }>({});
  const numberOfCitations = useRef(0);

  useEffect(() => {
    window.addEventListener("hashchange", scrollToHighlightFromHash, false);
    fetchAnnotations();

    return () => {
      window.removeEventListener(
        "hashchange",
        scrollToHighlightFromHash,
        false,
      );
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchAnnotations = async () => {
    let sources: string[] =
      citedSources
        ?.filter((src) => src?.parsed)
        .map((src) => src.getAllAnnotations())
        .flat() ?? [];
    numberOfCitations.current = sources.length;

    let sidebarSources: string[] =
      citedSources
        ?.filter((src) => src?.parsed)
        .map((src) => src.getFirstAnnotation()) ?? [];

    let additionalSourcesArray: string[] =
      additionalSources?.map((src) => src.getAllAnnotations()).flat() ?? [];

    sidebarSources.push(
      ...(additionalSources
        ?.filter((src) => src?.parsed)
        .map((src) => src.getFirstAnnotation()) ?? []),
    );

    const orderedSources = [...sources, ...additionalSourcesArray];
    let query = supabase
      .from("annotations")
      .select("*", { count: "exact" })
      .in("id", orderedSources);

    let { data: annotations, error } = await query;

    if (error) {
      console.error("Error fetching data:", error);
      return;
    } else if (!annotations || annotations.length === 0) {
      console.log("No data found.");
      return;
    } else {
      console.log("Data retrieved:", annotations);
    }

    let firstUrl = "";

    let groupedHighlights: IHighlight[][] = annotations.map((annotation) => {
      let filename = `/content/${annotation["filename"]}`;
      if (!firstUrl) {
        firstUrl = filename;
      }
      let boxes = annotation["boxes"];

      let pageNumbers: Set<number> = new Set(
        boxes.map((box: any) => box["page"]),
      );
      let annotationHighlights: IHighlight[] = [];
      for (const page of pageNumbers) {
        const highlight: RfHighlight = {
          id: annotation["id"],
          fileName: `${annotation["filename"]}`,
          isContinued: !sidebarSources.includes(annotation["id"]),
          position: {
            boundingRect: {
              x1: boxes[0]["coords"][0],
              y1: boxes[0]["coords"][1],
              x2: boxes[0]["coords"][4],
              y2: boxes[0]["coords"][5],
              width: 1,
              height: 1,
            },
            rects: annotation["boxes"]
              .filter((box: any) => page === box["page"])
              .map((box: any) => ({
                x1: box["coords"][0],
                y1: box["coords"][1],
                x2: box["coords"][4],
                y2: box["coords"][5],
                width: 1,
                height: 1,
              })),
            pageNumber: page,
          },
          content: {
            text: "",
          },
          comment: {
            text: annotation["section"],
            emoji: "",
          },
        };
        annotationHighlights.push(highlight);
      }
      references.current[annotation.id] = {
        filename,
        highlights: annotationHighlights,
      };

      return annotationHighlights;
    });

    // Sort results based on the original order in `orderedSources`.
    groupedHighlights = groupedHighlights
      .filter((highlight) => highlight.length)
      .sort(
        (a, b) =>
          orderedSources.indexOf(a[0].id) - orderedSources.indexOf(b[0].id),
      );

    setHighlights(groupedHighlights);
    setUrl(firstUrl);
  };
  return {
    url,
    highlights,
    references,
    numberOfCitations,
    setUrl,
  };
};
