type Props = {
  title: string;
  children: React.ReactNode;
};

export default function AccordionSkeleton({ title, children }: Props) {
  return (
    <div role="status" className={`mb-3 max-w-[1200px] duration-300 sm:mb-12`}>
      <div className="flex h-[40px] w-full items-center justify-between gap-3 bg-rf-light-beige px-3 text-left sm:h-[50px]">
        <h2 className="subheader-font line-clamp-1 break-all text-[20px]">
          {title}
        </h2>
      </div>
      <div className={"overflow-hidden bg-white p-1.5 sm:p-6"}>{children}</div>{" "}
      <span className="sr-only">Loading...</span>
    </div>
  );
}
