import { useEffect, useRef } from "react";
import { useMapsLibrary } from "@vis.gl/react-google-maps";

/**
 * useAutocompleteServices
 * Custom hook that initializes Google Maps `AutocompleteService` and `PlacesService` instances.
 *
 * @returns An object containing:
 * - `autocompleteService`: A mutable ref to the initialized `AutocompleteService` instance, allowing the app to fetch autocomplete predictions.
 * - `placesService`: A mutable ref to the initialized `PlacesService` instance, used to retrieve place details.
 *
 * @remarks
 * - Initializes `AutocompleteService` and `PlacesService` only once, when the `places` library is available.
 * - These services are necessary to provide autocomplete predictions and detailed place information for user addresses.
 */

export function useAutocompleteServices() {
  const places = useMapsLibrary("places");
  const autocompleteService =
    useRef<google.maps.places.AutocompleteService | null>(null);
  const placesService = useRef<google.maps.places.PlacesService | null>(null);

  useEffect(() => {
    if (!places) return;

    autocompleteService.current = new google.maps.places.AutocompleteService();
    const mapDiv = document.createElement("div");
    placesService.current = new google.maps.places.PlacesService(mapDiv);
  }, [places]);

  return { autocompleteService, placesService };
}
