export class JsonValidationError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'JsonValidationError';
  }
}

export function validateJsonFormat(jsonString: string): boolean {
  try {
    if (!jsonString.trim()) {
      throw new JsonValidationError('JSON format cannot be empty');
    }
    
    const parsed = JSON.parse(jsonString);
    
    if (typeof parsed !== 'object' || parsed === null) {
      throw new JsonValidationError('JSON must be an object');
    }
    
    return true;
  } catch (error) {
    if (error instanceof JsonValidationError) {
      throw error;
    }
    if (error instanceof SyntaxError) {
      throw new JsonValidationError('Invalid JSON format: ' + error.message);
    }
    throw new JsonValidationError('Invalid JSON format');
  }
}