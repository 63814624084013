import { twMerge } from "tailwind-merge";
import { TriangleDown16Filled } from "@fluentui/react-icons";
import useScrollVisibility from "./useScrollVisibility";
import { isAndroid } from "../../helpers/deviceDetection";
import FixedPositionWrapper from "../ui/FixedPositionWrapper/FixedPositionWrapper";

export default function BackToTop() {
  const showButton = useScrollVisibility(500);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <FixedPositionWrapper placement="bottom-left">
      <button
        onClick={scrollToTop}
        className={twMerge(
          "h-10 w-10 rotate-180 rounded-full bg-rf-dark-brown text-white shadow-md duration-300 hover:scale-105",
          showButton ? "block" : "hidden",
          isAndroid() ? "hidden sm:block" : "",
        )}
      >
        <TriangleDown16Filled />
      </button>
    </FixedPositionWrapper>
  );
}
