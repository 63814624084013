// hooks/useScreenWidth.ts
import { useEffect } from "react";
import { useAppDispatch } from "../../app/hooks";
import { setScreenWidth } from "../../app/globals/screenSlice";

//Custom hook that tracks and updates the screen width in the Redux store with a debounce on window resize.

export const useScreenWidth = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout>;

    const handleResize = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        dispatch(setScreenWidth(window.innerWidth));
      }, 500);
    };

    dispatch(setScreenWidth(window.innerWidth));

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      clearTimeout(timeoutId);
    };
  }, [dispatch]);
};
