export class ParsedAnnotation {
    completeLink: string;
    fileName: string;
    pageNumber?: number;
    pageNumberRangeStart: number;
    pageNumberRangeEnd?: number;
    annotationStub: string;
    parsed: boolean;

    constructor(annotationInput: string) {
        let re = /\[?\[?(.+\.pdf)\?annotation=(.*?)-page-(\d+)-?(\d+)*\]?\]?/;
        let matches = annotationInput.match(re);
        if (matches == null)
            throw new Error("Annotation input does not match format");

        this.completeLink = matches[0];
        this.fileName = matches[1];
        this.annotationStub = matches[2];
        this.pageNumberRangeStart = Number(matches[3]);
        this.pageNumberRangeEnd = matches[4] ? Number(matches[4]) : undefined;
        this.parsed = true;
    }

    getAllAnnotations(): string[] {
        let annotations: string[] = [];
        for (let i = this.pageNumberRangeStart; i <= (this.pageNumberRangeEnd ?? this.pageNumberRangeStart); i++) {
            annotations.push(this.annotationStub + "-page-" + i)
        }
        return annotations;
    }

    getFirstAnnotation(): string {
        return this.annotationStub + "-page-" + this.pageNumberRangeStart
    }
}
