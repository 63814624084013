import { useAppSelector } from "../../../../app/hooks";
import * as LosAngeles from "../../../../regiondata/losangeles";
import { TriangleDown16Filled } from "@fluentui/react-icons";
import { useState } from "react";
import { twMerge } from "tailwind-merge";
import CollapsibleSection from "./CollapsibleSection";
import { UseFormRegister, UseFormSetValue } from "react-hook-form";
import { AddressSearchFormType } from "../../AddressSearchSection";
import ZoningFilters from "./ZoningFilters";

type Props = {
  register: UseFormRegister<AddressSearchFormType>;
  setValue: UseFormSetValue<AddressSearchFormType>;
  miscTypeSelected: Set<string>;
  permitSubType: string | undefined;
  programType: string | undefined;
  zoningType: string | undefined;
  address: string | undefined;
  coordinates: { lat: number; lng: number } | undefined;
};

export default function OptionalModifiers({
  register,
  setValue,
  miscTypeSelected,
  programType,
  permitSubType,
  address,
  coordinates,
  zoningType,
}: Props) {
  const { isLoading } = useAppSelector((state) => state.addressSearch);

  const disableInputs = isLoading;

  const [isMenuOpen, setIsMenuOpen] = useState(true);

  const [isPermitTypeOpen, setIsPermitTypeOpen] = useState(true);
  const [isProgramTypeOpen, setIsProgramTypeOpen] = useState(true);
  const [isOtherTypeOpen, setIsOtherTypeOpen] = useState(true);

  const programTypeOptions: SelectableSource[] = LosAngeles.programTypeOptions;
  const miscTypeOptions: SelectableSource[] = LosAngeles.miscTypeOptions;

  const checkboxClass = twMerge(
    '"my-[0.5px] md:my-[2px]" block h-5 w-5 hover:border-rf-vibrant-red  cursor-pointer select-none rounded-md text-rf-vibrant-red duration-150 focus:border-rf-vibrant-red focus:ring-rf-vibrant-red sm:my-[1.25px]',
    disableInputs && "cursor-auto opacity-50  cursor-not-allowed	",
  );

  const labelClass = twMerge(
    "desc-font grow cursor-pointer select-none pl-2.5 line-clamp-3",
    disableInputs && "cursor-auto opacity-50 cursor-not-allowed	",
  );

  const permitTypeOptions = {
    all: "All",
    "1 or 2 Family Dwelling": "1 or 2 Family Dwelling",
    apartment: "Apartment",
    commercial: "Commercial",
  };

  const handleMiscTypeChange = (itemId: string, isChecked: boolean) => {
    const updatedSet = new Set(miscTypeSelected);
    if (isChecked) {
      updatedSet.add(itemId);
    } else {
      updatedSet.delete(itemId);
    }
    setValue("miscTypeSelected", updatedSet);
  };

  const handlePermitTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value =
      permitSubType === e.target.value ? undefined : [e.target.value];
    setValue("permitSubType", value);
  };

  const handleProgramTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = programType === e.target.value ? undefined : [e.target.value];

    setValue("programType", value);
  };

  return (
    <div>
      <button
        type="button"
        onClick={() => setIsMenuOpen((prev) => !prev)}
        className="mx-auto flex w-full max-w-[462px] items-center gap-2 lg:mx-0"
      >
        <TriangleDown16Filled
          className={`${!isMenuOpen && "-rotate-90"} mt-[2px] text-rf-dark-brown duration-300`}
        />
        <p className="desc-font text-left">
          (Optional) Apply modifiers to your report
        </p>
      </button>
      {isMenuOpen && (
        <div className="mt-3 flex flex-col flex-wrap items-center justify-between gap-6 lg:mt-6 lg:flex-row lg:items-start xl:mt-8">
          {/* Zoning Filters */}
          <ZoningFilters
            zoningType={zoningType}
            setValue={setValue}
            address={address}
            coordinates={coordinates}
          />

          {/* Permit Type */}

          <CollapsibleSection title="Filter Permitting Timelines by permit type">
            {Object.entries(permitTypeOptions).map(([key, name], i) => {
              if (i !== 0 && !isPermitTypeOpen) return null;
              return (
                <div key={key} className={`flex w-full items-start`}>
                  <input
                    type="checkbox"
                    {...register("permitSubType", {
                      onChange: handlePermitTypeChange,
                    })}
                    value={key}
                    className={checkboxClass}
                    id={key}
                    disabled={disableInputs}
                  />
                  <label htmlFor={key} className={labelClass}>
                    {name}
                  </label>
                  {i === 0 && (
                    <button
                      type="button"
                      onClick={() => setIsPermitTypeOpen(!isPermitTypeOpen)}
                      className="h-6 w-6 flex-shrink-0 translate-x-2"
                    >
                      <TriangleDown16Filled
                        className={`${!isPermitTypeOpen && "rotate-90"} mb-[3px] text-rf-dark-brown duration-300`}
                      />
                    </button>
                  )}
                </div>
              );
            })}
          </CollapsibleSection>

          {/* Special Program */}
          <CollapsibleSection title=" Special Program (Optional):">
            {programTypeOptions.map((item, index) => {
              if (index !== 0 && !isProgramTypeOpen) return null;

              return (
                <div key={index} className={`flex w-full items-start`}>
                  <input
                    type="checkbox"
                    {...register("programType", {
                      onChange: handleProgramTypeChange,
                    })}
                    value={item.id}
                    name="programType"
                    className={checkboxClass}
                    id={item.id}
                    disabled={disableInputs}
                  />
                  <label htmlFor={item.id} className={labelClass}>
                    {item.name}
                  </label>
                  {index === 0 && (
                    <button
                      type="button"
                      className="h-6 w-6 flex-shrink-0 translate-x-2"
                      onClick={() => setIsProgramTypeOpen(!isProgramTypeOpen)}
                    >
                      <TriangleDown16Filled
                        className={`${!isProgramTypeOpen && "rotate-90"} mb-[3px] text-rf-dark-brown duration-300`}
                      />
                    </button>
                  )}
                </div>
              );
            })}
          </CollapsibleSection>

          {/* Other Sources */}
          <CollapsibleSection
            title={
              <>
                Select data for inclusion: <br /> Specialty Data Sources
              </>
            }
          >
            {miscTypeOptions.map((item, index) => {
              if (index !== 0 && !isOtherTypeOpen) return null;

              return (
                <div key={index} className={`flex w-full items-start`}>
                  <input
                    type="checkbox"
                    id={"chk_" + item.id}
                    className={checkboxClass}
                    checked={miscTypeSelected.has(item.id)}
                    disabled={disableInputs}
                    onChange={(e) =>
                      handleMiscTypeChange(item.id, e.target.checked)
                    }
                  />
                  <label htmlFor={"chk_" + item.id} className={labelClass}>
                    {item.name}
                  </label>
                  {index === 0 && (
                    <button
                      className="h-6 w-6 flex-shrink-0 translate-x-2"
                      type="button"
                      onClick={() => setIsOtherTypeOpen(!isOtherTypeOpen)}
                    >
                      <TriangleDown16Filled
                        className={`${!isOtherTypeOpen && "rotate-90"} mb-[3px] text-rf-dark-brown duration-300`}
                      />
                    </button>
                  )}{" "}
                </div>
              );
            })}
          </CollapsibleSection>
        </div>
      )}
    </div>
  );
}
