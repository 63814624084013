import AddressSearch from "../addressSearch/AddressSearch";
import React from "react";
import { AdminUI } from "./AdminUI";
import Layout from "../../layouts/Layout";
import UserAdmin from "./user/UserAdmin";
import OverlayAdmin from "./overlays/OverlayAdmin";
import ChecklistBuilder from "./checklistBuilder/ChecklistBuilder";
import StoredSearch from "../../components/ChecklistBuilder/StoredReportView";
import StoredReportView from "../../components/ChecklistBuilder/StoredReportView";

const layout = <Layout />;

export const adminRoutes = {
  path: "/admin/",
  children: [
    {
      index: true,
      element: (
        <div className="grid grid-cols-1 gap-3">
          <a href="#/admin/overlays">Overlays</a>
          <a href="#/admin/users">Users</a>
          <a href="#/admin/documents">Documents</a>
          <a href="#/admin/checklist">Checklist</a>
        </div>
      ),
    },
    {
      path: "settings",
      element: <div>Admin Settings Page </div>,
    },
    {
      path: "users",
      element: <UserAdmin />,
    },
    {
      path: "overlays",
      element: <OverlayAdmin />,
    },
    {
      path: "documents",
      element: <div>Document Admin (not implemented) </div>,
    },
    {
      path: "checklist",
      element: <ChecklistBuilder />,
    },
    {
      path: "report/:id",
      element: <StoredReportView />
    },
    {
      path: "*",
      element: (
        <div className="grid grid-cols-1 gap-3">
          <a href="#/admin/overlays">Overlays</a>
          <a href="#/admin/users">Users</a>
          <a href="#/admin/documents">Documents</a>
          <a href="#/admin/checklist">Alpha Checklist</a>
        </div>
      ),
    },
  ],
};
