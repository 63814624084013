import AccordionSkeleton from "../../../components/ui/loaders/AccordionSkeleton";
import TextSkeleton from "../../../components/ui/loaders/TextSkeleton";

export default function JurisdictionSkeleton() {
  return (
    <AccordionSkeleton title="Jurisdiction">
      <>
        <div className="flex gap-6">
          <TextSkeleton lines={5} columns={2} lineWidth={70} />

          <div className="h-[160px] w-full animate-pulse bg-rf-light-gray sm:h-[320px]"></div>
        </div>
      </>
    </AccordionSkeleton>
  );
}
