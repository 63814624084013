import React, { useState } from "react";
import { twMerge } from "tailwind-merge";
import TextArea from "../../../../components/forms/TextArea/TextArea";
import { useFetchUserAddressesQuery } from "../../addressSearchSectionApiSlice";
import SearchAddressAutocomplete from "./SearchAddressAutocomplete";
import { TriangleDown16Filled } from "@fluentui/react-icons";
import SearchAddressSaved from "./SearchAddressSaved";
import { StoredAddress } from "../../../../api/userConfig";
import { setHasReportBeenGenerated } from "../../addressSearchSectionSlice";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { UseFormSetValue } from "react-hook-form";
import type { AddressSearchFormType } from "../../AddressSearchSection";
import { useAutocompletePredictions } from "../../hooks/useAutocompletePredictions";
import { useAutocompleteServices } from "../../hooks/useAutocompleteServices";

type Props = {
  address: string | undefined;
  setValue: UseFormSetValue<AddressSearchFormType>;
};

export default function SearchInput({ address, setValue }: Props) {
  const { session } = useAppSelector((state) => state.user);

  const dispatch = useAppDispatch();
  const { isLoading } = useAppSelector((state) => state.addressSearch);

  const [isAutocompleteOpen, setIsAutocompleteOpen] = useState(false);
  const [isSavedAddressesOpen, setIsSavedAddressesOpen] = useState(false);
  const [inputValue, setInputValue] = useState<string>(address ?? "");

  const userId = session ? session.user.id : "";

  const { data: savedAddressData } = useFetchUserAddressesQuery(userId, {
    skip: userId === "",
  });

  const { autocompleteService, placesService } = useAutocompleteServices();
  const predictions = useAutocompletePredictions(
    inputValue,
    autocompleteService,
  );

  const getPlaceDetails = (placeId: string) => {
    if (!placesService.current) return;

    const request = {
      placeId,
      fields: ["geometry.location"],
    };

    placesService.current.getDetails(request, (place, status) => {
      if (status === google.maps.places.PlacesServiceStatus.OK && place) {
        const location = place.geometry?.location;
        if (location)
          setValue("coordinates", { lat: location.lat(), lng: location.lng() });
      } else {
        console.error("Error fetching place details:", status);
      }
    });
  };

  const handleCloseAutocomplete = () => setIsAutocompleteOpen(false);

  const handleSelectAddress = (
    place: google.maps.places.AutocompletePrediction,
  ) => {
    handleInputChange(place.description);
    dispatch(setHasReportBeenGenerated(false));
    setValue("address", place.description);
    setIsAutocompleteOpen(false);
    getPlaceDetails(place.place_id);
  };

  const handleInputChange = (inputValue: string) => {
    const input = inputValue.trim();

    setInputValue(inputValue);
    if (input.length > 0) {
      setIsAutocompleteOpen(true);
    } else {
      setIsAutocompleteOpen(false);
    }
  };

  const handleToggleSavedAddress = () => {
    if (isLoading) return;
    setIsAutocompleteOpen(false);

    setIsSavedAddressesOpen((prev) => !prev);
  };

  const handleSelectSavedAddress = (address: StoredAddress) => {
    setIsSavedAddressesOpen(false);
    handleInputChange(address.address);
    setValue("address", address.address);

    dispatch(setHasReportBeenGenerated(false));

    setValue("coordinates", { lat: address.lng, lng: address.lat });
  };

  const handleCloseSavedAddress = () => {
    setIsSavedAddressesOpen(false);
  };

  const handleInputFocus = () => {
    if (inputValue.length > 0) {
      setIsAutocompleteOpen(true);
      setIsSavedAddressesOpen(false);
    }
  };

  const onBlur = () => {
    if (inputValue !== address && address && address?.length > 0)
      handleInputChange(address);
  };

  const showSavedDataComps = savedAddressData && savedAddressData.length > 0;

  return (
    <div className="relative">
      <div className="relative" id={"address-input"}>
        <TextArea
          placeholder="Enter address here"
          roundBottom={
            (!isSavedAddressesOpen && !isAutocompleteOpen) ||
            (isSavedAddressesOpen && savedAddressData?.length === 0) ||
            (isAutocompleteOpen && predictions.length === 0)
          }
          value={inputValue}
          onFocus={handleInputFocus}
          onBlur={onBlur}
          onChange={handleInputChange}
          disabled={isLoading}
          className={`${showSavedDataComps && "pr-14"}`}
        />
        <div className="absolute right-[18px] top-1/2 flex -translate-y-1/2 items-center border border-transparent border-l-rf-dark-brown pl-3">
          {showSavedDataComps && (
            <button
              type="button"
              id="open-dropdown-button"
              onClick={handleToggleSavedAddress}
              className={twMerge(
                `pb-[2px] text-rf-dark-brown outline-none duration-300`,
                !isSavedAddressesOpen ? "rotate-90" : "",
                isLoading && "cursor-not-allowed",
              )}
            >
              <TriangleDown16Filled className="text-rf-dark-brown" />
            </button>
          )}
        </div>
      </div>

      <SearchAddressSaved
        closeDropdown={handleCloseSavedAddress}
        isOpen={isSavedAddressesOpen}
        onAddressSelect={handleSelectSavedAddress}
      />

      <SearchAddressAutocomplete
        predictions={predictions}
        address={inputValue}
        isOpen={isAutocompleteOpen}
        closeDropdown={handleCloseAutocomplete}
        onPlaceSelect={handleSelectAddress}
      />
    </div>
  );
}
