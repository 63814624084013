import { useEffect, useState } from "react";

// Custom hook that toggles visibility of a fixed side navigation based on scroll position.

function useShowFixedSideNav(elementId: string) {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const element = document.getElementById(elementId);
      if (element) {
        const elementBottomPosition = element.getBoundingClientRect().bottom;
        const scrollTop = window.scrollY || window.pageYOffset;

        if (scrollTop > elementBottomPosition + window.pageYOffset) {
          setIsVisible(true);
        } else {
          setIsVisible(false);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [elementId]);

  return isVisible;
}

export default useShowFixedSideNav;
