import Spinner from "./Spinner";
import TextSkeleton from "./TextSkeleton";

type Props = {
  title?: string;
};
export default function ListDetailsSkeleton({ title }: Props) {
  const btns = Array(7)
    .fill(0)
    .map((_, i) => i);

  return (
    <div role="status" className={`my-1.5`}>
      <div
        className={`flex h-[30px] w-full items-center justify-between gap-3 rounded-t-md bg-rf-very-light-gray px-3 text-left`}
      >
        <div className="h-3 w-[120px] rounded-full bg-rf-light-beige md:h-4 md:w-[180px]"></div>
      </div>

      <div className="flex max-h-[800px] flex-col gap-1.5 overflow-hidden rounded-b-md bg-rf-very-light-gray sm:max-h-fit sm:overflow-hidden md:flex-row">
        <div className="mb-3 hidden max-h-[80vh] w-full basis-[100%] overflow-y-hidden pr-1.5 md:block md:max-w-[300px] lg:max-w-[400px]">
          <div className="absolute w-[384px] border-b-[1px] border-rf-light-gray" />
          {btns.map((_, i) => (
            <div
              className={`flex h-[90px] w-full flex-col justify-center border-b-[1px] border-rf-light-gray px-6 text-left`}
              key={i}
            >
              <TextSkeleton lines={2} />
            </div>
          ))}
        </div>

        <div className="relative block px-3 md:hidden">
          <div
            className={`flex h-[70px] w-full flex-col justify-center border-b-[1px] border-rf-light-gray bg-rf-light-beige px-6 text-left`}
          >
            <TextSkeleton lines={2} lineWidth={60} />
          </div>
        </div>

        <div className="relative h-[700px] md:h-auto md:flex-1">
          <div className="absolute inset-0 m-3 mt-0 border-b-[1px] border-r-[1px] border-rf-light-gray p-3 shadow-[inset_2px_2px_3px_2px_rgba(0,0,0,0.1)]">
            <div className="flex h-full max-h-full flex-col overflow-hidden">
              <Spinner />
            </div>
          </div>
        </div>
      </div>

      <span className="sr-only">Loading...</span>
    </div>
  );
}
