import { createApi } from "@reduxjs/toolkit/query/react";
import { Config } from "../../api/models";
import { supabase } from "../../api/db";
import type { StoredAddress } from "../../api/userConfig";
import { AddressOptions } from "../../api/models";
import { baseQuery } from "../../app/globals/baseQuery";
import { getHeaders } from "../../api";

export const addressSearchApi = createApi({
  reducerPath: "addressSearchApi",
  tagTypes: ["SearchConfig", "StoredAddresses", "ZoningOptions"],

  baseQuery: baseQuery,
  endpoints: (builder) => ({
    getSearchConfig: builder.query<Config, void>({
      query: () => `/config`,
      keepUnusedDataFor: 60 * 10,
      providesTags: () => ["SearchConfig"],
    }),
    fetchUserAddresses: builder.query<StoredAddress[], string>({
      async queryFn(userId) {
        try {
          const {
            data: addresses,
            error,
            count,
          } = await supabase
            .from("stored_addresses")
            .select("*", { count: "exact" })
            .eq("user_id", userId);

          if (error) {
            return {
              error: { status: 500, statusText: error.message },
            };
          }

          return {
            data: addresses || [],
          };
        } catch (err: unknown) {
          return {
            error: {
              status: "CUSTOM_ERROR",
              error:
                (err instanceof Error ? err.message : "Unknown error") ||
                "Unknown error occurred",
              data: undefined,
            },
          };
        }
      },
      providesTags: (result, error, userId) =>
        result
          ? [
              { type: "StoredAddresses", id: "LIST" },
              ...result.map((address) => ({
                type: "StoredAddresses" as const,
                id: address.id,
              })),
              { type: "StoredAddresses", id: userId },
            ]
          : [{ type: "StoredAddresses", id: "LIST" }],
    }),

    getZoningOptions: builder.query<
      AddressOptions,
      {
        address: string[];
        coordinates?: number[];
      }
    >({
      keepUnusedDataFor: 600,
      async queryFn(request) {
        try {
          const headers = await getHeaders();
          const response = await fetch(`/address_options`, {
            method: "POST",
            headers: {
              ...headers,
              "Content-Type": "application/json",
            },
            body: JSON.stringify(request),
          });

          if (!response.ok) {
            return {
              error: {
                status: response.status,
                statusText: response.statusText,
              },
            };
          }

          const parsedResponse: AddressOptions = await response.json();
          return { data: parsedResponse };
        } catch (err: unknown) {
          return {
            error: {
              status: "CUSTOM_ERROR",
              error: err instanceof Error ? err.message : "Unknown error",
              data: undefined,
            },
          };
        }
      },
      providesTags: ["ZoningOptions"],
    }),
  }),
});

export const {
  useGetSearchConfigQuery,
  useFetchUserAddressesQuery,
  useGetZoningOptionsQuery,
} = addressSearchApi;
