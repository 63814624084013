import { useState, useEffect } from "react";

// Hook that toggles back to top button visibility based on scroll position exceeding a threshold

function useScrollVisibility(scrollYThreshold: number) {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsVisible(window.scrollY > scrollYThreshold);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scrollYThreshold]);

  return isVisible;
}

export default useScrollVisibility;
