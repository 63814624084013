import React, { useEffect, useState } from 'react';
import { Dropdown, IDropdownOption } from '@fluentui/react/lib/Dropdown';
import { TextField, PrimaryButton } from '@fluentui/react';
import {Overlay} from "../../../api";
import {OverlayCrud} from "./OverlayCrud";

const OverlayManager: React.FC = () => {
    const [overlays, setOverlays] = useState<Overlay[]>([]);
    const [selectedOverlay, setSelectedOverlay] = useState<Overlay | undefined>(undefined);
    const overlayService = new OverlayCrud();

    useEffect(() => {
        loadOverlays();
    }, []);

    const loadOverlays = async () => {
        try {
            const data = await overlayService.getOverlays();
            setOverlays(data);
        } catch (error) {
            console.error('Failed to fetch overlays:', error);
        }
    };

    const handleOverlayChange = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
        const overlay = overlays.find(o => o.id === option?.key);
        setSelectedOverlay(overlay);
    };

    const handleInputChange = (field: keyof Overlay, value: string) => {
        if (selectedOverlay) {
            setSelectedOverlay({ ...selectedOverlay, [field]: value });
        }
    };

    const handleUpdateOverlay = async () => {
        if (selectedOverlay) {
            try {
                await overlayService.updateOverlay(selectedOverlay.id, {
                    name: selectedOverlay.name,
                    description: selectedOverlay.description,
                    url: selectedOverlay.url,
                });
                await loadOverlays(); // Refresh the list
            } catch (error) {
                console.error('Failed to update overlay:', error);
            }
        }
    };

    return (
        <div className="p-4">
            <Dropdown
                placeholder="Select an overlay"
                options={overlays.map((overlay) => ({
                    key: overlay.id,
                    text: overlay.name,
                }))}
                onChange={handleOverlayChange}
                selectedKey={selectedOverlay?.id}
                className="mb-4"
            />

            {selectedOverlay && (
                <div className="bg-white p-4 rounded-lg shadow-md">
                    <TextField
                        label="Name"
                        value={selectedOverlay.name}
                        onChange={(e, newValue) => handleInputChange('name', newValue || '')}
                        className="mb-4"
                    />
                    <TextField
                        label="Description"
                        multiline
                        rows={4}
                        value={selectedOverlay.description}
                        onChange={(e, newValue) => handleInputChange('description', newValue || '')}
                        className="mb-4"
                    />
                    <TextField
                        label="URL"
                        value={selectedOverlay.url}
                        onChange={(e, newValue) => handleInputChange('url', newValue || '')}
                        className="mb-4"
                    />
                    <PrimaryButton text="Update Overlay" onClick={handleUpdateOverlay} />
                </div>
            )}
        </div>
    );
};

export default OverlayManager;
