import { supabase, User, SubscriptionData } from "../../../api/db"; // Assuming this is your Supabase client

export class UserSubscriptionCrud {

    // Create a new user
    async createUser(user: User): Promise<string | null> {
        const { data, error } = await supabase
            .from('users')
            .insert(user)
            .select('id');

        if (error) {
            console.error('Error creating user:', error);
            return null;
        }

        return data?.[0]?.id || null;
    }

    // Get a user by ID
    async getUser(id: string): Promise<User | null> {
        const { data, error } = await supabase
            .from('users')
            .select('*')
            .eq('id', id)
            .single();

        if (error) {
            console.error('Error fetching user:', error);
            return null;
        }

        return data;
    }

    // Update a user by ID
    async updateUser(id: string, updatedUser: Partial<User>): Promise<boolean> {
        const { error } = await supabase
            .from('users')
            .update(updatedUser)
            .eq('id', id);

        if (error) {
            console.error('Error updating user:', error);
            return false;
        }

        return true;
    }

    // Delete a user by ID
    async deleteUser(id: string): Promise<boolean> {
        const { error } = await supabase
            .from('users')
            .delete()
            .eq('id', id);

        if (error) {
            console.error('Error deleting user:', error);
            return false;
        }

        return true;
    }

    // Create a new subscription
    async createSubscription(subscription: SubscriptionData): Promise<string | null> {
        const { data, error } = await supabase
            .from('subscriptions')
            .insert(subscription)
            .select('id');

        if (error) {
            console.error('Error creating subscription:', error);
            return null;
        }

        return data?.[0]?.id || null;
    }

    // Get a subscription by ID
    async getSubscription(id: string): Promise<SubscriptionData | null> {
        const { data, error } = await supabase
            .from('subscriptions')
            .select('*')
            .eq('id', id)
            .single()

        if (error) {
            console.error('Error fetching subscription:', error);
            return null;
        }

        return data;
    }

    // Update a subscription by ID
    async updateSubscription(id: string, updatedSubscription: Partial<SubscriptionData>): Promise<boolean> {
        const { error } = await supabase
            .from('subscriptions')
            .update(updatedSubscription)
            .eq('id', id);

        if (error) {
            console.error('Error updating subscription:', error);
            return false;
        }

        return true;
    }

    // Delete a subscription by ID
    async deleteSubscription(id: string): Promise<boolean> {
        const { error } = await supabase
            .from('subscriptions')
            .delete()
            .eq('id', id);

        if (error) {
            console.error('Error deleting subscription:', error);
            return false;
        }

        return true;
    }

    // Example of a custom method: Get all subscriptions for a user
    async getSubscriptionsForUser(userId: string): Promise<SubscriptionData[] | null> {
        const { data, error } = await supabase
            .from('subscriptions')
            .select('*')
            .eq('user_id', userId)
            .returns<SubscriptionData[]>();

        if (error) {
            console.error('Error fetching subscriptions for user:', error);
            return null;
        }

        return data;
    }

    async getAllAuthUsers(): Promise<User[] | null> {
        let me = await supabase.auth.getUser();

        let sesh = await supabase.auth.getSession();

        const { data, error } = await supabase
            .from('users')
            .select('*')

        if (error) {
            console.error('Error fetching user:', error);
            return null;
        }

        return data;
    }

}
