import React, { useState, useEffect, useMemo, useRef } from "react";
import Chart from "react-apexcharts";
import BarGraphSkeleton from "../ui/loaders/BarGraphSkeleton";

interface Props {
  data: { [key: string]: string };
}

export default function BarChartSequence({ data }: Props) {
  const [renderedCharts, setRenderedCharts] = useState<number>(0);

  const keys = useMemo(() => Object.keys(data), [data]);
  const requestRef = useRef<number | null>(null); // Reference for requestAnimationFrame
  const renderDelay = 200; // Delay between each chart render (in ms)

  useEffect(() => {
    const batchSize = 1; // Number of charts to render at a time
    setRenderedCharts(0);
    const renderBatch = () => {
      setRenderedCharts((prev) => {
        const nextCount = Math.min(prev + batchSize, keys.length);
        if (nextCount < keys.length) {
          // Continue rendering charts
          requestRef.current = window.setTimeout(renderBatch, renderDelay);
        }
        return nextCount;
      });
    };

    // Delay the initial rendering to improve UI responsiveness
    const initialRenderTimeout = setTimeout(() => {
      requestRef.current = window.setTimeout(renderBatch, renderDelay); // Start rendering with delay
    }, 500); // Initial render delay (500ms)

    // Clean up timeout and setInterval when component unmounts
    return () => {
      if (requestRef.current) clearTimeout(requestRef.current);
      clearTimeout(initialRenderTimeout);
    };
  }, [keys.length, renderDelay]);

  const elements = useMemo(() => {
    return keys.map((key, index) => {
      if (index >= renderedCharts) {
        // Show loading block while waiting for chart to render
        return (
          <div key={key} className="h-[305px] sm:h-[484px] lg:h-[315px]">
            <BarGraphSkeleton />
          </div>
        );
      }


      let bars: { [key: string]: number } = {};
      if (typeof data[key] === 'string') {
        bars = JSON.parse(data[key]);
      }
      // Currently getting different JSON strings. Handle them all. Will standardize with protobuf later.
      else if (typeof data[key] === 'object') {
        if (Object.keys(data[key]).every(k => !isNaN(Number(k)))) {
          // dict {int:int}
          Object.entries(data[key]).forEach(([k, v]) => bars[k.toString()] = Number(v))
        } else {
          // dict {string:int}
          Object.entries(data[key]).forEach(([k, v]) => bars[k] = Number(v))
        }
      } else {
        console.error(`Unexpected data type for key ${key}`);
        bars = {};
      }

      // Ensure all values in bars are numbers
      bars = Object.fromEntries(
        Object.entries(bars).map(([k, v]) => [k, Number(v)])
      );

      const maxWeeks = Math.max(...Object.keys(bars).map(Number));

      const buckets = Array.from(
        { length: maxWeeks + 1 },
        (_, i) => bars[i] ?? 0,
      );

      const xAxisTicksDistance =
        maxWeeks > 250 ? 50 : maxWeeks > 50 ? 10 : maxWeeks > 5 ? 5 : maxWeeks;

      const options = {
        chart: {
          type: "bar",
          toolbar: {
            show: false,
          },
          animations: {
            enabled: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          categories: Array.from({ length: maxWeeks + 1 }).map((_, i) =>
            i % xAxisTicksDistance === 0 ? `${i}` : "",
          ),
          hideOverlappingLabels: true,
          title: {
            text: "Approval Time (Weeks)",
          },
        },
        yaxis: {
          show: true,
          tickAmount: 10,
          labels: {
            show: false,
            formatter: (value: number) => value.toFixed(0),
          },
          title: {
            text: "Count",
          },
        },
        title: {
          text: key,
          align: "left",
        },
      };

      const series = [
        {
          name: key,
          data: buckets,
        },
      ];

      return (
        <Chart
          key={key}
          //@ts-ignore
          options={options}
          series={series}
          type="bar"
          width="90%"
        />
      );
    });
  }, [data, renderedCharts, keys]);

  return (
    <div className="animated-element m-2 grid grid-cols-1 gap-3 lg:grid-cols-2">
      {elements}
    </div>
  );
}
