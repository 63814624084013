import React, { useState, useRef } from "react";
import { twMerge } from "tailwind-merge";
import { TriangleDown16Filled } from "@fluentui/react-icons";

type Props = {
  title?: string;
  secondaryTitle?: string;
  children?: React.ReactNode;
  className?: string;
  collapsible?: boolean;
  isInitiallyOpen?: boolean;
  childPadding?: boolean;
};

export default function ReportSection({
  title,
  children,
  secondaryTitle,
  className,
  collapsible = false,
  isInitiallyOpen = true,
  childPadding = true,
}: Props) {
  const [isOpen, setIsOpen] = useState(isInitiallyOpen);
  const [isRendered, setIsRendered] = useState(isInitiallyOpen);
  const timeoutRef = useRef<number | undefined>(undefined);

  // this is here for mobile container scrolls. so that it animates smoothly.
  const toggleAccordion = () => {
    if (collapsible) {
      // Clear any active timeout when toggling
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      if (isOpen) {
        // If closing, delay the removal from the DOM to allow animation
        timeoutRef.current = window.setTimeout(() => {
          setIsRendered(false);
          timeoutRef.current = undefined; // Reset after clearing
        }, 300);
      } else {
        // If opening, render immediately before animation
        setIsRendered(true);
      }

      setIsOpen((prev) => !prev);
    }
  };

  return (
    <div className={twMerge(" ", className)}>
      <button
        onClick={toggleAccordion}
        aria-expanded={isOpen}
        className={twMerge(
          `flex h-[30px] w-full items-center gap-3 rounded-[5px] bg-rf-very-light-gray px-[10px] text-left ${collapsible && children ? "cursor-pointer" : "cursor-default"} `,
          !childPadding && "rounded-b-none",
        )}
      >
        <h3 className="body-font line-clamp-1 grow break-all font-bold">
          {title}
        </h3>

        <h3 className="body-font line-clamp-1 shrink-0 break-all font-bold">
          {secondaryTitle}
        </h3>

        {collapsible && children && (
          <div
            className={twMerge(
              "mb-[3px] shrink-0 text-[20px] transition-transform duration-300",
              isOpen ? "" : "rotate-90",
            )}
          >
            <TriangleDown16Filled className="text-rf-dark-brown" />
          </div>
        )}
      </button>

      {children && (
        <div
          className={twMerge(
            "animated-element grid bg-white transition-all duration-300 ease-in-out",
            isOpen
              ? "grid-rows-[1fr] opacity-100"
              : "grid-rows-[0fr] opacity-0",
            childPadding && (isOpen ? "m-1.5 sm:m-5" : " "),
          )}
        >
          {isRendered && (
            <div
              className={twMerge(
                "thin-scrollbar max-h-[820px] overflow-y-auto sm:max-h-fit sm:overflow-hidden",
                childPadding && "pr-1.5 sm:pr-0",
              )}
            >
              {children}
            </div>
          )}
        </div>
      )}
    </div>
  );
}
