export const categoryIconMap: Map<string, string> = new Map<string, string>([
  ["Urban Development and Infrastructure", "🏗️"],
  ["Environmental Impact and Sustainability", "🌳"],
  ["Housing and Community Planning", "🏡"],
  ["Public Works and Utilities", "🛠"],
  ["Historic and Cultural Preservation", "🗽"],
  ["Government and Administrative Actions", "🏛️"],
  ["Public Health and Safety", "🚨"],
  ["Transportation and Mobility", "🚴‍"],
  ["Legal and Regulatory Compliance", "⚖️"],
  ["Community and Cultural Events", "🎉"],
]);
