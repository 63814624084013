import React, { useEffect, useState } from "react";
import { twMerge } from "tailwind-merge";
import { PanelLeftText24Regular } from "@fluentui/react-icons";
import { COLORS } from "../../../helpers/constants";

type Props = {
  isOpen: boolean;
  onClose?: () => void;
  children: React.ReactNode;
  maxWidth?: number;
  side?: "left" | "right";
};

const animationDuration = 300;

export default function Drawer({
  isOpen,
  onClose,
  children,
  maxWidth = 280,
  side = "right",
}: Props) {
  const [isDrawerOpen, setIsDrawerOpen] = useState(isOpen);

  const [delayOnClose, setDelayOnClose] = useState(isDrawerOpen);
  const [hideTrigger, setHideTrigger] = useState(isDrawerOpen);

  const toggleDrawer = () => {
    setHideTrigger(true);

    if (onClose) onClose();
    setIsDrawerOpen((prev) => !prev);
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        toggleDrawer();
      }
    };
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  useEffect(() => {
    if (isDrawerOpen) {
      setDelayOnClose(true);
    } else {
      const timeoutId = setTimeout(() => {
        setDelayOnClose(false);
        setHideTrigger(false);
      }, animationDuration);

      return () => clearTimeout(timeoutId);
    }
  }, [isDrawerOpen]);

  const toggleBtn = (
    <button
      className={twMerge(
        "flex h-[40px] w-[40px] items-center justify-center rounded-full",
        !delayOnClose && "bg-rf-light-gray",
        hideTrigger && "duration-300 hover:bg-white hover:bg-opacity-10",
      )}
      onClick={toggleDrawer}
    >
      <PanelLeftText24Regular primaryFill={`white`} />
    </button>
  );

  return (
    // drawer wrapper
    <div
      className={twMerge(
        `flex h-full w-fit min-w-[80px] flex-col`,
        side === "right" ? "items-end" : "items-start",
      )}
    >
      {!hideTrigger && <div className="z-0 px-5 pt-[12px]">{toggleBtn}</div>}
      {/* drawer */}
      <div
        className={twMerge(
          `z-10 h-full ease-in-out`,
          side === "right" ? "origin-right" : "origin-left",
          delayOnClose ? "bg-rf-very-dark-gray" : "bg-transparent",
        )}
        style={{
          width: isDrawerOpen ? `${maxWidth}px` : "0px",
          transitionProperty: "width",
          transitionDuration: `${animationDuration}ms`,
        }}
      >
        {/* Close button */}
        <div
          className={twMerge(
            `z-10 flex items-center px-5 pt-[12px]`,
            isDrawerOpen ? "scale-x-100 opacity-100" : "scale-x-0 opacity-0",
            side === "right"
              ? "origin-right justify-end"
              : "origin-left justify-start",
          )}
        >
          {toggleBtn}
        </div>

        {/* Drawer content */}
        <div
          className={twMerge(
            `p-5`,
            isDrawerOpen ? "scale-x-100 opacity-100" : "scale-x-0 opacity-0",
            side === "right" ? "origin-right" : "origin-left",
          )}
        >
          {children}
        </div>
      </div>
    </div>
  );
}
