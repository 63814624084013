import Accordion from "../../components/ui/Accordion/Accordion";
import { MotionsContent } from "./components/MotionsContent";
import RelevantMotionsSkeleton from "./components/RelevantMotionsSkeleton";
import { useAppSelector } from "../../app/hooks";
import { RelevantMotionsInfo } from "../../helpers/constants";

export default function RelevantMotions() {
  const {
    endpointOutputs: { siteInfoResponse },
    isLoading,
  } = useAppSelector((state) => state.addressSearch);

  if (isLoading) return <RelevantMotionsSkeleton />;

  if (!siteInfoResponse?.council_district) return <> </>;

  return (
    <Accordion id={RelevantMotionsInfo.id} title={RelevantMotionsInfo.title}>
      <MotionsContent />
    </Accordion>
  );
}
