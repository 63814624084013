import { ModalTypes } from "./modalSlice";

export const getModalTitle = (modalType: ModalTypes): string => {
  switch (modalType) {
    case "MotionsViewer":
      return "Motion";
    case "SearchError":
      return "Couldn't generate a report";
    case "SavedAddresses":
      return "My Addresses";
    default:
      return "";
  }
};
