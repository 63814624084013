import React, { useMemo } from "react";
import Accordion from "../../components/ui/Accordion/Accordion";
import ReportSection from "../../components/ui/ReportSection/ReportSection";
import KeyValueSection from "../../components/ui/KeyValueSection/KeyValueSection";
import { UnfilteredKeyValueContentType } from "../../helpers/checkValidObjValues";
import { filterValidContent } from "../../helpers/checkValidObjValues";
import PermittingTimelineSkeleton from "./components/PermittingTimelineSkeleton";
import ChartTypeToggle from "./components/ChartTypeToggle";
import { useAppSelector } from "../../app/hooks";
import { PermittingTimelineInfo } from "../../helpers/constants";

export default function PermittingTimeline() {
  const {
    endpointOutputs: { siteInfoResponse, permitTimeline },
    isLoading,
  } = useAppSelector((state) => state.addressSearch);

 
  const NotEnoughDataText = "Not Enough Data";
  const NotEnoughDataThreshold = 10;

  const tickerText = permitTimeline
    ? "Median " +
      (permitTimeline.zone_filter
        ? "Zone: " + permitTimeline.zone_filter
        : "") +
      (permitTimeline.council_district
        ? " District: " + permitTimeline.council_district
        : "")
    : "";



  const summaryContent: UnfilteredKeyValueContentType = useMemo(
    () => {
      if (!permitTimeline) {
        return [];
      }
      const buildingPermitsText: string = (!permitTimeline.building_n || permitTimeline.building_n < NotEnoughDataThreshold) ?
          NotEnoughDataText :
          `${Math.floor(permitTimeline.building_q1)} to ${Math.ceil(permitTimeline.building_q3)} weeks`;
      const mechanicalPermitsText: string = (!permitTimeline.mechanical_n || permitTimeline.mechanical_n < NotEnoughDataThreshold) ?
          NotEnoughDataText :
          `${Math.floor(permitTimeline.mechanical_q1)} to ${Math.ceil(permitTimeline.mechanical_q3)} weeks`;
      const electricalPermitsText: string = (!permitTimeline.electrical_n || permitTimeline.electrical_n < NotEnoughDataThreshold) ?
          NotEnoughDataText :
          `${Math.floor(permitTimeline.electrical_q1)} to ${Math.ceil(permitTimeline.electrical_q3)} weeks`;
      return [
            {
              ["Building Permits"]: buildingPermitsText,
            },
            {
              ["Mechanical Permits"]: mechanicalPermitsText,
            },
            {
              ["Electrical Permits"]: electricalPermitsText,
            },
          ]},
    [permitTimeline],
  );
  const filteredSummaryContent = filterValidContent(summaryContent);

  if (isLoading && !permitTimeline) return <PermittingTimelineSkeleton />;

  if (!permitTimeline || !siteInfoResponse)
    return <></>;

  if (permitTimeline.building_median < 0)
    return (
      <Accordion id={PermittingTimelineInfo.id} title={PermittingTimelineInfo.title}>
        <p className="body-font">
          No similar projects found with this type, district, and zone.
        </p>
      </Accordion>
    );

  return (
    <>
      <Accordion id={PermittingTimelineInfo.id} title={PermittingTimelineInfo.title}>
        <div className="flex flex-col gap-1.5">
          {/* Summary of Permitting Timelines in this District */}
          <ReportSection title="Summary of Permitting Timelines in this District">
            {filteredSummaryContent.length > 0 ? (
                <KeyValueSection
                    content={filteredSummaryContent}
                />
            ) : undefined}
          </ReportSection>

          {(permitTimeline.building_n >= NotEnoughDataThreshold) &&
              <ReportSection
                  isInitiallyOpen={false}
                  collapsible
                  title="Building Permits"
              >
                <>
                  {Object.keys(permitTimeline.bar_data_building_business_unit).length > 0 &&
                      <>
                        <p className={"body-font text-center font-bold"}>
                          Distribution of Approval Timelines by Plan Check Approval Type
                        </p>
                        <ChartTypeToggle
                            tickerText={tickerText}
                            barChartData={permitTimeline.bar_data_building_business_unit}
                            medianTickerValue={permitTimeline.building_median}
                            boxPlotsData={permitTimeline.box_data_building_business_unit}
                        />
                      </>
                  }

                  {Object.keys(permitTimeline.bar_data_building_permit_type).length > 0 &&
                      <>
                        <p className={"body-font pt-3 text-center font-bold"}>
                          Distribution of Approval Timelines by Plan Check Permit Type
                        </p>
                        <ChartTypeToggle
                            defaultBarChart={false}
                            tickerText={tickerText}
                            barChartData={permitTimeline.bar_data_building_permit_type}
                            medianTickerValue={permitTimeline.building_median}
                            boxPlotsData={permitTimeline.box_data_building_permit_type}
                        />
                      </>
                  }
                </>
              </ReportSection>}
          {(permitTimeline.mechanical_n >= NotEnoughDataThreshold) &&
              <ReportSection
                  isInitiallyOpen={false}
                  collapsible
                  title="Mechanical Permits"
              >
                <>
                  {Object.keys(permitTimeline.bar_data_mechanical_business_unit).length > 0 &&
                      <>
                        <p className={"body-font pt-3 text-center font-bold"}>
                          Distribution of Approval Timelines by Plan Check Approval Type
                        </p>
                        <ChartTypeToggle
                            tickerText={tickerText}
                            barChartData={permitTimeline.bar_data_mechanical_business_unit}
                            medianTickerValue={permitTimeline.mechanical_median}
                            boxPlotsData={permitTimeline.box_data_mechanical_business_unit}
                        />

                      </>
                  }

                  {Object.keys(permitTimeline.bar_data_mechanical_permit_type).length > 0 &&
                      <>
                        <p className={"body-font pt-3 text-center font-bold"}>
                          Distribution of Approval Timelines by Plan Check Permit Type
                        </p>
                        <ChartTypeToggle
                            tickerText={tickerText}
                            barChartData={permitTimeline.bar_data_mechanical_permit_type}
                            medianTickerValue={permitTimeline.mechanical_median}
                            boxPlotsData={permitTimeline.box_data_mechanical_permit_type}
                        />
                      </>
                  }

                </>
              </ReportSection>}
          {(permitTimeline.electrical_n >= NotEnoughDataThreshold) &&
              <ReportSection
                  isInitiallyOpen={false}
                  collapsible
                  title="Electrical Permits"
              >
                <>
                  {Object.keys(permitTimeline.box_data_electrical_business_unit).length > 0 &&
                      <>
                        <p className={"body-font pt-3 text-center font-bold"}>
                          Distribution of Approval Timelines by Plan Check Approval Type
                        </p>
                        <ChartTypeToggle
                            tickerText={tickerText}
                            barChartData={permitTimeline.bar_data_electrical_business_unit}
                            medianTickerValue={permitTimeline.electrical_median}
                            boxPlotsData={permitTimeline.box_data_electrical_business_unit}
                        />
                      </>
                  }
                  {Object.keys(permitTimeline.bar_data_electrical_permit_type).length > 0 &&
                      <>
                        <p className={"body-font pt-3 text-center font-bold"}>
                          Distribution of Approval Timelines by Plan Check Permit Type
                        </p>
                        <ChartTypeToggle
                            defaultBarChart={false}
                            tickerText={tickerText}
                            barChartData={permitTimeline.bar_data_electrical_permit_type}
                            medianTickerValue={permitTimeline.electrical_median}
                            boxPlotsData={permitTimeline.box_data_electrical_permit_type}
                        />

                      </>
                  }
                </>
              </ReportSection>}

          <p className={"desc-font text-center"}>
            {`From in last 5 years of permit data in zone: "${permitTimeline.zone_filter}" council district: ${siteInfoResponse?.council_district}`}
          </p>
        </div>
      </Accordion>
    </>
  );
}
