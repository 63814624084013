import {supabase} from "./db";

export interface StoredUserQuery {
    id: number;
    query: string;
    user_id: string;
}

export interface StoredAddress {
    id: number;
    address: string;
    lat: number;
    lng: number;
    user_id: string;
}

export async function fetchUserQueries(userId: string): Promise<StoredUserQuery[]> {
    const { data: questionResp, error, count } = await supabase
        .from("stored_searches")
        .select("*", { count: "exact" }) // Include the count of all results
        .eq("user_id", userId); // Fetch based on the user's ID

    if (error) {
        console.error("Error fetching questions:", error);
        return []; // Return an empty array on error
    }
    return questionResp || []; // Return the data or an empty array if data is undefined
}

// CREATE
export async function createQuery(question: { query: string; user_id: string }): Promise<StoredUserQuery | null> {
    const { data, error } = await supabase
        .from("stored_searches") // Replace with your table name
        .insert([question])
        .select();

    if (error) {
        console.error("Error creating question:", error);
        return null;
    }

    return data ? data[0] : null; // Return the created question or null
}

// UPDATE
export async function updateQuery(query: StoredUserQuery): Promise<StoredUserQuery | null> {
    const { data, error } = await supabase
        .from("stored_searches")
        .update({ question: query.query }) // Update the question field
        .eq("id", query.id) // Assuming you have an ID field
        .select();

    if (error) {
        console.error("Error updating query:", error);
        return null;
    }

    return data ? data[0] : null;
}

// DELETE
export async function deleteQuery(queryId: number): Promise<boolean> {
    const { error } = await supabase
        .from("stored_searches")
        .delete()
        .eq("id", queryId);

    if (error) {
        console.error("Error deleting query:", error);
        return false;
    }

    return true;
}


export async function fetchUserAddresses(userId: string): Promise<StoredAddress[]> {
    const { data: questionResp, error, count } = await supabase
        .from("stored_addresses")
        .select("*", { count: "exact" }) // Include the count of all results
        .eq("user_id", userId); // Fetch based on the user's ID

    if (error) {
        console.error("Error fetching questions:", error);
        return []; // Return an empty array on error
    }
    return questionResp || []; // Return the data or an empty array if data is undefined
}

// CREATE
export async function createAddress(question: { address: string; user_id: string, lat: number,  lng: number}): Promise<StoredAddress | null> {
    const { data, error } = await supabase
        .from("stored_addresses") // Replace with your table name
        .insert([question])
        .select();

    if (error) {
        console.error("Error creating question:", error);
        return null;
    }

    return data ? data[0] : null; // Return the created question or null
}

// UPDATE
export async function updateAddress(address: StoredAddress): Promise<StoredAddress | null> {
    const { data, error } = await supabase
        .from("stored_addresses")
        .update({ question: address.address }) // Update the question field
        .eq("id", address.id) // Assuming you have an ID field
        .select();

    if (error) {
        console.error("Error updating address:", error);
        return null;
    }

    return data ? data[0] : null;
}

// DELETE
export async function deleteAddress(addressId: number): Promise<boolean> {
    const { error } = await supabase
        .from("stored_addresses")
        .delete()
        .eq("id", addressId);

    if (error) {
        console.error("Error deleting address:", error);
        return false;
    }

    return true;
}
