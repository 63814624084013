import { useEffect, useRef, useState } from "react";
import { useMapsLibrary } from "@vis.gl/react-google-maps";
import type { AddressSearchFormType } from "../AddressSearchSection"; // Adjust the import path as needed
import { UseFormSetValue } from "react-hook-form";
import { useSearchParams } from "react-router-dom";

/**
 * Custom hook to validate an address and coordinates using Google Places API.
 *
 * This hook checks if the provided address and coordinates are valid using Google Places
 * `findPlaceFromQuery`. If validation is successful, it sets the address and coordinates
 * in the form using `setValue`.
 *
 * @param addressParam - The address string from URL parameters to validate.
 * @param coordsParams - The coordinates object with `lat` and `lng` from URL parameters.
 * @param setValue - The `setValue` function from `react-hook-form` to update form values.
 */

type UseValidateAddressProps = {
  setValue?: UseFormSetValue<AddressSearchFormType>;
};

export function useValidateAddress({ setValue }: UseValidateAddressProps) {
  const [searchParams] = useSearchParams();

  const addressParam = searchParams.get("address") ?? "";
  const promptParam = searchParams.get("prompt") ?? "";
  const latParam = searchParams.get("lat");
  const lngParam = searchParams.get("lng");
  const coordsParams: AddressSearchFormType["coordinates"] =
    latParam && lngParam
      ? { lat: Number(latParam), lng: Number(lngParam) }
      : undefined;

  const [isLoading, setIsLoading] = useState<boolean>(!!addressParam);

  const places = useMapsLibrary("places");
  const placesService = useRef<google.maps.places.PlacesService | null>(null);

  const [validatedAddress, setValidatedAddress] = useState<string | null>(null);
  const [validatedCoordinates, setValidatedCoordinates] = useState<
    AddressSearchFormType["coordinates"] | null
  >(null);

  useEffect(() => {
    if (!places) return;

    if (!placesService.current) {
      const mapDiv = document.createElement("div");
      placesService.current = new google.maps.places.PlacesService(mapDiv);
    }

    const validateAndSetAddress = () => {
      if (!placesService.current || !addressParam) return;
      setIsLoading(true);
      const request = {
        query: addressParam,
        fields: ["place_id", "geometry"],
      };

      placesService.current.findPlaceFromQuery(request, (results, status) => {
        setIsLoading(false);

        if (
          status === google.maps.places.PlacesServiceStatus.OK &&
          results &&
          results[0]
        ) {
          const place = results[0];
          const location = place.geometry?.location;

          // If valid coords were provided, verify they match the place's coords
          if (coordsParams && location) {
            if (
              Math.abs(location.lat() - coordsParams.lat) < 0.01 &&
              Math.abs(location.lng() - coordsParams.lng) < 0.01
            ) {
              if (setValue) {
                setValue("address", addressParam);
                setValue("userPrompt", promptParam);
                setValue("coordinates", {
                  lat: location.lat(),
                  lng: location.lng(),
                });
              }

              setValidatedAddress(addressParam);
              setValidatedCoordinates({
                lat: location.lat(),
                lng: location.lng(),
              });
            }
          } else if (location) {
            // If coordsParams is undefined, use the valid coords from Places API
            const validatedCoords = {
              lat: location.lat(),
              lng: location.lng(),
            };

            if (setValue) {
              setValue("address", addressParam);
              setValue("userPrompt", promptParam);
              setValue("coordinates", validatedCoords);
            }

            setValidatedAddress(addressParam);
            setValidatedCoordinates(validatedCoords);
          }
        } else {
          console.error("Invalid address or coordinates provided");
        }
      });
    };

    validateAndSetAddress();
  }, [places]);

  return {
    isLoading,
    validatedAddress,
    validatedCoordinates,
    searchParams: !!addressParam,
  };
}
