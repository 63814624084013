import React, { useMemo } from "react";
import ParcelOnMap from "./components/ParcelOnMap";
import {
  filterValidContent,
  UnfilteredKeyValueContentType,
} from "../../helpers/checkValidObjValues";
import Accordion from "../../components/ui/Accordion/Accordion";
import KeyValueSection from "../../components/ui/KeyValueSection/KeyValueSection";
import JurisdictionSkeleton from "./components/JurisdictionSkeleton";
import { useAppSelector } from "../../app/hooks";
import { JurisdictionInfo } from "../../helpers/constants";

export default function Jurisdiction() {
  const {
    endpointOutputs: { siteInfoResponse },
    isLoading,
  } = useAppSelector((state) => state.addressSearch);

  const jurisdiction = siteInfoResponse
    ? siteInfoResponse.jurisdiction
    : undefined;

  const jurisdictionContent: UnfilteredKeyValueContentType = useMemo(
    () =>
      jurisdiction
        ? [
            {
              ["Council District"]:
                (jurisdiction?.council_district ?? 0) > 0
                  ? jurisdiction.council_district?.toString()
                  : undefined,
            },
            {
              ["Area Planning Commission"]: jurisdiction.apc,
            },
            {
              ["Neighborhood Council"]: jurisdiction.neighborhood_council,
            },
            {
              ["Community Plan"]: jurisdiction.community_plan,
            },
            {
              ["Addresses"]: jurisdiction.addresses,
            },
            {
              ["Lot Area"]: jurisdiction.lot_area
                ? `${jurisdiction.lot_area.toFixed(1)} (sq ft)`
                : undefined,
            },
          ]
        : [],
    [jurisdiction],
  );

  const filteredJurisdictionContent = filterValidContent(jurisdictionContent);

  if (isLoading) return <JurisdictionSkeleton />;

  if (!siteInfoResponse || !jurisdiction) return <></>;

  return (
    <Accordion id={JurisdictionInfo.id} title={JurisdictionInfo.title}>
      <div className="flex w-full flex-col gap-3 lg:flex-row">
        <div className="w-full lg:w-[60%] xl:w-[50%]">
          <KeyValueSection keysBold content={filteredJurisdictionContent} />
        </div>
        {jurisdiction.geometry && (
          <ParcelOnMap
            className="h-[350px] w-full overflow-clip rounded-[10px] lg:w-[40%] xl:w-[50%]"
            geometry={jurisdiction.geometry}
          />
        )}
      </div>
    </Accordion>
  );
}
