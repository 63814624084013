/**
 * Formats a key by replacing underscores with spaces and capitalizing the first letter of each word.
 * Example: "zoning_description" -> "Zoning Description"
 */
export function capitalizeKey(key: string): string {
    return key
      .split('_') // Split the key by underscores
      .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
      .join(' '); // Join the words with spaces
  }
  