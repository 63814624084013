import ReportSectionSkeleton from "../../../components/ui/loaders/ReportSectionSkeleton";
import TextSkeleton from "../../../components/ui/loaders/TextSkeleton";
import AccordionSkeleton from "../../../components/ui/loaders/AccordionSkeleton";

export default function PlanningAndZoningSkeleton() {
  return (
    <AccordionSkeleton title="Planning & Zoning">
      <>
        <ReportSectionSkeleton title secondaryTitle></ReportSectionSkeleton>

        <ReportSectionSkeleton title secondaryTitle>
          <TextSkeleton title lines={5} />
        </ReportSectionSkeleton>

        <ReportSectionSkeleton title>
          <TextSkeleton instances={2} title lines={4} />
        </ReportSectionSkeleton>

        <ReportSectionSkeleton title secondaryTitle>
          <TextSkeleton title lines={5} />
        </ReportSectionSkeleton>
      </>
    </AccordionSkeleton>
  );
}
