import {ChecklistRequest, submitChecklist} from "../../../components/ChecklistBuilder/api";
import React, {useEffect, useState} from "react";
import {validateJsonFormat} from "../../../components/ChecklistBuilder/jsonValidation";
import {JsonViewer, QueryInput} from "../../../components/ChecklistBuilder";
import {ChecklistOptions} from "../../../components/ChecklistBuilder/ChecklistOptions";
import {supabase} from "../../../api";
import StoredReportView from "../../../components/ChecklistBuilder/StoredReportView";

interface Location {
  address?: string;
  lat?: number;
  lng?: number;
}

interface FormState {
  location: Location | null;
  intermediatePrompt?: string;
  finalizedPrompt?: string;
}

interface ChecklistSearchResponse {
  report_id?: string;
  results: any[]
}

class StoredSearch {
  constructor(
    public id: number = 0,
    public query: string = '',
    public outputFormat: string = ''
  ) {}

  static fromJson(json: any): StoredSearch {
    return new StoredSearch(
      json.id,
      json.query,
      json.output_format
    );
  }

  toJson(search: StoredSearch): object {
    const idDef = search.id !== 0 ? { id: search.id } : {};

    return {
      ...idDef,
      query: search.query,
      output_format: search.outputFormat
    };
  }
}
class ChecklistFetcher {
  async getChecklistItems(checklistName: string): Promise<StoredSearch[]> {
    const {data, error} = await supabase.rpc('get_stored_searches_for_checklist', {
      checklist_name: checklistName
    })

    if (error) {
      console.error('Error fetching stored searches for checklist:', error)
      return []
    }

    return (data as any[]).map(StoredSearch.fromJson);
  }

async updateChecklistItems(items: StoredSearch[]): Promise<void> {
  try {
    // First, upsert the StoredSearch items
    const { data, error } = await supabase.from('stored_searches')
      .upsert(items.map(item => item.toJson(item)))
      .select();

    if (error) throw error;

    if (!data) throw new Error('No data returned from upsert operation');

    // Then, upsert the checklist_stored_searches entries
    const { error: linkError } = await supabase.from('checklist_stored_searches')
      .upsert(
        data.map((item: any) => ({
            'checklist_id': 10,
            'stored_search_id': item.id == 0 ? null : item.id,
        }))
      );

    if (linkError) throw linkError;

    console.log('Checklist items updated successfully');
  } catch (error) {
    console.error('Error updating stored searches for checklist:', error);
    throw error; // Re-throw the error for the caller to handle
    }
  }

  async deleteChecklistItems(itemsToDelete: StoredSearch[]): Promise<void> {
    try {
      // Delete the entries from checklist_stored_searches
      const { error: unlinkError } = await supabase
        .from('checklist_stored_searches')
        .delete()
        .in('stored_search_id', itemsToDelete.map(item => item.id));

      if (unlinkError) throw unlinkError;

      // Delete the StoredSearch items
      const { error: deleteError } = await supabase
        .from('stored_searches')
        .delete()
        .in('id', itemsToDelete.map(item => item.id));

      if (deleteError) throw deleteError;

      console.log('Checklist items deleted successfully');
    } catch (error) {
      console.error('Error deleting stored searches for checklist:', error);
      throw error; // Re-throw the error for the caller to handle
}
  }
}
function ChecklistBuilder() {
  const [queries, setQueries] = useState<StoredSearch[]>([
    new StoredSearch()
  ]);

  const [formState, setFormState] = useState<FormState>({
    location: null,
    intermediatePrompt: '',
    finalizedPrompt: ''
  });

  const [response, setResponse] = useState<any>(undefined);
  const [reportId, setReportId] = useState<number>();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string>('');

  const handleAddQuery = () => {
    if (queries.length < 10) {
      setQueries([...queries, new StoredSearch()]);
    }
  };
  useEffect(() => {
    let fetcher = new ChecklistFetcher();
    fetcher.getChecklistItems('Alpha Checklist').then((items) => {
      setQueries(items)
      validateQueries()
    })
  }, []);

  const handleQueryChange = (index: number, field: string, value: string) => {
    const newQueries = [...queries];
    const updatedSearch = new StoredSearch(
      newQueries[index].id,
      field === 'query' ? value : newQueries[index].query,
      field === 'outputFormat' ? value : newQueries[index].outputFormat
    );
    newQueries[index] = updatedSearch;
    setQueries(newQueries);
    setError('');
  };

  const handleQuerySave = (index: number) => {
    let fetcher = new ChecklistFetcher();
    fetcher.updateChecklistItems([queries[index]]).then(() => {

    })
  }


  const handleDeleteQuery = (index: number) => {
    if (queries.length > 1) {
      setQueries(queries.filter((_, i) => i !== index));
      setError('');
      let fetcher = new ChecklistFetcher();
      fetcher.deleteChecklistItems([queries[index]]).then(r => {});
    }
  };

  const handleFormStateChange = (updates: Partial<FormState>) => {
  setFormState((prev) => {
    const newState = { ...prev, ...updates };
    // Ensure that location is properly updated
    if (updates.location) {
      newState.location = { ...prev.location, ...updates.location };
    }
    return newState;
  });
    setError('');
  };

  const validateQueries = (): boolean => {
    try {
      // Validate all queries have content
      const emptyQueries = queries.find(q => !q.query.trim() || !q.outputFormat.trim());
      if (emptyQueries) {
        throw new Error('All queries and formats must be filled out');
      }

      // Validate all JSON formats
      queries.forEach((q, index) => {
        try {
          validateJsonFormat(q.outputFormat);
        } catch (error) {
          throw new Error(`Query ${index + 1}: ${error instanceof Error ? error.message : 'Invalid JSON format'}`);
        }
      });

      return true;
    } catch (error) {
      setError(error instanceof Error ? error.message : 'Validation failed');
      return false;
    }
  };


  const updateChecklist = async (checklistItems: ChecklistRequest[]) => {
    try {
      const { data, error } = await supabase.rpc('update_stored_searches', {
        checklist_name: 'Alpha Checklist',
        new_searches: checklistItems.map(item => ({
          query: item.query,
          output_format: item.format
        }))
      });

      if (error) throw error;

      console.log('Checklist updated successfully:', data);
    } catch (error) {
      console.error('Error updating checklist:', error);
      setError('Failed to update checklist in the database');
    }

  };
  const handleSubmit = async () => {
    setError('');

    if (!validateQueries()) {
      return;
    }
    setIsLoading(true);

    try {
      const checklistRequests: ChecklistRequest[] = queries.map(q => ({
        query: q.query,
        format: q.outputFormat
      }));

      const response = await submitChecklist(checklistRequests,
        [formState.location!.lat!, formState.location!.lng!], formState.location!.address,
        formState.intermediatePrompt, formState.finalizedPrompt);
      let response_data: object[] = response.results.map(response => {
        return {
          answer: JSON.parse(response['choices'][0]['message']['content']),
          id : 0
        };
      })

      setReportId(response.report_id)

      setResponse( response_data);
    } catch (error) {
      setError(error instanceof Error ? error.message : 'Failed to process queries');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-4xl mx-auto space-y-8">
        <div className="text-center">
          <h1 className="text-3xl font-bold text-gray-900 mb-2">Checklist Item Builder</h1>
          <p className="text-gray-600">Build your questions with JSON output formats</p>
        </div>
        <ChecklistOptions
          formState={formState}
          onChange={handleFormStateChange}/>

        <div className="space-y-6">
          {queries.map((query, index) => (
            <QueryInput
              key={index}
              query={query}
              index={index}
              onChange={handleQueryChange}
              onDelete={handleDeleteQuery}
              onSave={handleQuerySave}
            />
          ))}

          {queries.length < 10 && (
            <button
              onClick={handleAddQuery}
              className="w-full py-4 border-2 border-dashed border-gray-300 rounded-lg text-gray-600 hover:border-blue-500 hover:text-blue-500 transition-colors flex items-center justify-center gap-2"
            >
              {/*<PlusCircle size={20} />*/}
              Add Query
            </button>
          )}
        </div>

        {error && (
          <div className="bg-red-50 border-l-4 border-red-500 p-4">
            <div className="flex">
              <div className="flex-shrink-0">
                {/*<AlertCircle className="h-5 w-5 text-red-400" />*/}
              </div>
              <div className="ml-3">
                <p className="text-sm text-red-700">{error}</p>
              </div>
            </div>
          </div>
        )}

        <div className="flex justify-center">
          <button
            onClick={handleSubmit}
            disabled={isLoading}
            className="bg-blue-600 text-white px-8 py-3 rounded-lg font-medium hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:opacity-50 disabled:cursor-not-allowed transition-colors"
          >
            {isLoading ? 'Processing...' : 'Submit Queries'}
          </button>
        </div>

        {response && (
          <div className="space-y-4">
            <h2 className="text-xl font-semibold text-gray-900">Response</h2>

            {reportId && (
                <div className="flex items-center space-x-2">
                  <input
                    type="text"
                    readOnly
                    value={`${window.location.origin}/#/admin/report/${reportId}`}
                    className="flex-grow px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                  />
                  <button
                    onClick={() => navigator.clipboard.writeText(`${window.location.origin}/#/admin/report/${reportId}`)}
                    className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                  >
                    Copy
                  </button>
                </div>
            )}

            <StoredReportView propAnswers={response} />
          </div>
        )}
      </div>
    </div>
  );
}

export default ChecklistBuilder;
