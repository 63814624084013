import React, { useState, useRef } from "react";
import { twMerge } from "tailwind-merge";
import { TriangleDown16Filled } from "@fluentui/react-icons";

type Props = {
  title: string;
  children: React.ReactNode;
  className?: string;
  isInitiallyOpen?: boolean;
  id?: string;
};

export default function Accordion({
  title,
  children,
  className,
  isInitiallyOpen = true,
  id,
}: Props) {
  const [isOpen, setIsOpen] = useState(isInitiallyOpen);
  const [isRendered, setIsRendered] = useState(isInitiallyOpen);
  const timeoutRef = useRef<number | undefined>(undefined);

  const toggleAccordion = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    if (isOpen) {
      timeoutRef.current = window.setTimeout(() => {
        setIsRendered(false);
        timeoutRef.current = undefined;
      }, 300);
    } else {
      setIsRendered(true);
    }

    setIsOpen((prev) => !prev);
  };

  return (
    <div
      id={id}
      className={twMerge(
        `mb-1.5 max-w-[1200px] overflow-clip duration-300 sm:mb-3`,
        isOpen && "mb-3 sm:mb-12",
        className,
      )}
    >
      <button
        aria-expanded={isOpen}
        onClick={toggleAccordion}
        className={twMerge(
          "flex h-[40px] w-full items-center justify-between gap-3 rounded-t-[20px] bg-rf-red px-5 text-left duration-300 sm:h-[50px]",
          !isOpen && "rounded-b-[20px]",
        )}
      >
        <h2 className="subheader-font line-clamp-1 break-all text-[20px] font-bold">
          {title}
        </h2>
        <div
          className={twMerge(
            "mb-0.5 shrink-0 text-[20px] transition-transform duration-300",
            isOpen ? "" : "rotate-90",
          )}
        >
          <TriangleDown16Filled className="text-rf-dark-brown" />
        </div>
      </button>
      <div
        className={twMerge(
          "animated-element grid overflow-hidden rounded-b-[12px] sm:rounded-b-[20px] bg-white transition-all duration-300 ease-in-out",
          isOpen
            ? "grid-rows-[1fr] p-1.5 opacity-100 sm:p-6"
            : "grid-rows-[0fr] opacity-0",
        )}
      >
        {isRendered && <div className="overflow-hidden">{children}</div>}
      </div>
    </div>
  );
}
