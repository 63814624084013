import React, { useState, useEffect } from "react";
import { useAppSelector } from "../../app/hooks";
import {
  StoredUserQuery,
  createQuery,
  deleteQuery,
  fetchUserQueries,
} from "../../api/userConfig";

const Checklist: React.FC = () => {
  const { session } = useAppSelector((state) => state.user);

  const [entries, setEntries] = useState<StoredUserQuery[]>([]);
  const [newEntry, setNewEntry] = useState("");

  // Fetch initial entries (replace with your data source)
  useEffect(() => {
    if (!session?.user.id) {
      return;
    }
    fetchUserQueries(session.user.id).then(setEntries);
  }, [session]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewEntry(event.target.value);
  };

  const addEntry = async () => {
    if (!session) return;
    await createQuery({
      query: newEntry,
      user_id: session.user.id,
    }).then((created) => {
      if (!created) return;
      let updatedEntries = entries;
      updatedEntries.push(created);
      setNewEntry("");
    });
  };

  const deleteEntry = async (id: number) => {
    deleteQuery(id).then(() => {
      let updatedEntries = entries.filter((entry) => entry.id !== id);
      setEntries(updatedEntries);
    });
  };

  return (
    <div className="flex min-h-screen flex-col items-center justify-center bg-gray-100">
      <div className="w-full max-w-md rounded-lg bg-white p-8 shadow-md">
        <h2 className="mb-4 text-center text-2xl font-bold">My Checklist</h2>
        <div className={"flex-inline flex max-w-96 justify-between gap-2"}>
          <input
            type="text"
            value={newEntry}
            onChange={handleInputChange}
            className={"grow"}
          />
          <button
            className={"rounded border border-green-700 p-1 text-green-700"}
            onClick={addEntry}
          >
            Add
          </button>
        </div>
        <ul className={"max-w-96 py-2"}>
          {entries.map((entry) => (
            <li
              key={entry.id}
              className={"flex-inline flex justify-between py-1"}
            >
              {entry.query}
              <button
                className={"rounded border border-red-700 p-1 text-red-700"}
                onClick={() => deleteEntry(entry.id)}
              >
                Delete
              </button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Checklist;
