import React, { useState, useRef } from "react";
import { useGetZoningOptionsQuery } from "../../addressSearchSectionApiSlice";
import {
  TriangleDown16Filled,
  SpinnerIos20Regular,
} from "@fluentui/react-icons";
import { UseFormSetValue } from "react-hook-form";
import { AddressSearchFormType } from "../../AddressSearchSection";
import { Dismiss16Filled } from "@fluentui/react-icons";
import { useAppSelector } from "../../../../app/hooks";

type Props = {
  address: string | undefined;
  zoningType: string | undefined;
  coordinates: { lat: number; lng: number } | undefined;
  setValue: UseFormSetValue<AddressSearchFormType>;
};

export default function ZoningFilters({
  address,
  coordinates,
  zoningType,
  setValue,
}: Props) {
  const { isLoading } = useAppSelector((state) => state.addressSearch);

  const [searchQuery, setSearchQuery] = useState("");
  const [isOpen, setIsOpen] = useState(true);
  const listRef = useRef<HTMLDivElement>(null);
  const [prevAddress, setPrevAddress] = useState(address);

  const { data, isSuccess, error, isFetching } = useGetZoningOptionsQuery(
    {
      coordinates: [coordinates?.lat ?? 0, coordinates?.lng ?? 0],
      address: [address ?? ""],
    },
    { skip: !address || !coordinates || !isOpen },
  );

  if (address !== prevAddress) {
    setSearchQuery("");
    setValue("zoningType", undefined);
    setPrevAddress(address);
  }

  // Filter the dropdown options based on the search query
  const filteredZones =
    (data &&
      data?.all_zones?.filter((zone) =>
        zone.toLowerCase().includes(searchQuery.toLowerCase()),
      )) ??
    [];

  const disableInput =
    !!isFetching || data?.all_zones?.length === 0 || isLoading || !!error;

  const onBlur = (event: React.FocusEvent) => {
    if (
      listRef.current &&
      listRef.current.contains(event.relatedTarget as Node)
    ) {
      return;
    }
    if (searchQuery !== zoningType && zoningType && zoningType?.length > 0)
      setSearchQuery(zoningType);
  };

  const header = (
    <p className="desc-font mb-3 block min-h-fit font-bold tracking-tight lg:min-h-[42px]">
      View results for different zoning uses
    </p>
  );

  if (error)
    return (
      <div className="w-full max-w-[462px] grow basis-1 lg:max-w-[250px]">
        {header}
        <div className="mb-2 flex h-fit flex-col gap-1.5 rounded-md border border-rf-dark-brown px-3 py-2">
          {error && <p className="desc-font">Error loading options.</p>}
        </div>
      </div>
    );

  if (isFetching || isLoading)
    return (
      <div className="w-full max-w-[462px] grow basis-1 lg:max-w-[250px]">
        {header}
        <div className="mb-2 flex h-[140px] flex-col justify-center gap-1.5 rounded-md border border-rf-dark-brown px-3 py-2">
          <SpinnerIos20Regular className="mx-auto animate-spin" />
        </div>
      </div>
    );

  if (!isFetching && isSuccess && data?.all_zones?.length === 0)
    return (
      <div className="w-full max-w-[462px] grow basis-1 lg:max-w-[250px]">
        {header}
        <div className="mb-2 flex h-fit flex-col gap-1.5 rounded-md border border-rf-dark-brown px-3 py-2">
          {data?.all_zones?.length === 0 && (
            <p className="desc-font h-[140px]">
              No available zones for this region.
            </p>
          )}
        </div>
      </div>
    );

  return (
    <div
      ref={listRef}
      className="w-full max-w-[462px] grow basis-1 lg:max-w-[250px]"
    >
      {header}

      <div className="mb-2 flex h-fit flex-col gap-1.5 rounded-md border border-rf-dark-brown px-3 py-2">
        {/* Search Input */}
        <div className="flex items-center gap-4">
          <div className="relative w-full">
            <input
              onBlur={onBlur}
              type="text"
              disabled={disableInput}
              placeholder="Select Type (Optional)"
              value={searchQuery}
              onChange={(e) => {
                setIsOpen(true);
                setSearchQuery(e.target.value);
              }}
              className="desc-font w-full rounded-md border-rf-dark-brown px-2 py-1 pr-6 focus:border-transparent focus:outline-none focus:ring-1 focus:ring-rf-vibrant-red disabled:opacity-75"
            />
            {(zoningType || searchQuery.length > 0) && (
              <button
                type="button"
                className="shrink-0"
                onClick={() => {
                  setSearchQuery("");
                  setValue("zoningType", undefined);
                }}
              >
                <Dismiss16Filled
                  className={`absolute right-1.5 top-[52%] -translate-y-[50%] text-rf-dark-brown`}
                />
              </button>
            )}
          </div>
          <button
            type="button"
            className="shrink-0"
            onClick={() => setIsOpen((prev) => !prev)}
          >
            <TriangleDown16Filled
              className={`${!isOpen && "rotate-90"} mb-[3px] text-rf-dark-brown duration-300`}
            />
          </button>
        </div>
        {/* Dropdown Options */}
        {isOpen && (
          <>
            {filteredZones?.length > 0 && (
              <ul className="thin-scrollbar h-[140px] overflow-y-auto">
                {filteredZones.map((zone) => (
                  <li key={zone}>
                    <button
                      type="button"
                      onClick={() => {
                        if (zoningType !== zone) {
                          setValue("zoningType", zone);
                          setSearchQuery(zone);
                          setIsOpen(false);
                        }
                      }}
                      className="desc-font block w-full cursor-pointer rounded-md px-2 py-1 text-start duration-300 hover:bg-rf-very-light-gray active:bg-rf-light-gray"
                    >
                      {zone}
                    </button>
                  </li>
                ))}
              </ul>
            )}
          </>
        )}
      </div>
    </div>
  );
}
