import { createApi } from "@reduxjs/toolkit/query/react";
import { supabase } from "../../api/db";
import type { StoredAddress } from "../../api/userConfig";
import { addressSearchApi } from "./addressSearchSectionApiSlice";
import { baseQuery } from "../../app/globals/baseQuery";

export const addressSearchMutationsApi = createApi({
  reducerPath: "addressSearchMutationsApi",

  baseQuery: baseQuery,
  endpoints: (builder) => ({
    createAddress: builder.mutation<
      StoredAddress,
      {
        address: string;
        user_id: string;
        lng: number;
        lat: number;
      }
    >({
      async queryFn(newAddress, { dispatch }) {
        try {
          const { data, error } = await supabase
            .from("stored_addresses")
            .insert(newAddress)
            .single();

          if (error) {
            console.error("Error creating address:", error);
            return { error: { status: 500, statusText: error.message } };
          }

          dispatch(addressSearchApi.util.invalidateTags(["StoredAddresses"]));
          return { data };
        } catch (err: unknown) {
          return {
            error: {
              status: "CUSTOM_ERROR",
              error:
                (err instanceof Error ? err.message : "Unknown error") ||
                "Unknown error occurred",
              data: undefined,
            },
          };
        }
      },
    }),

    deleteAddress: builder.mutation<
      boolean,
      { addressId: number; userId: string }
    >({
      async queryFn({ addressId }) {
        try {
          const { error } = await supabase
            .from("stored_addresses")
            .delete()
            .eq("id", addressId);

          if (error) {
            console.error("Error deleting address:", error);
            return { error: { status: 500, statusText: error.message } };
          }

          return { data: true };
        } catch (err: unknown) {
          return {
            error: {
              status: "CUSTOM_ERROR",
              error:
                (err instanceof Error ? err.message : "Unknown error") ||
                "Unknown error occurred",
              data: undefined,
            },
          };
        }
      },
      async onQueryStarted(
        { addressId, userId },
        { dispatch, queryFulfilled },
      ) {
        const patchResult = dispatch(
          addressSearchApi.util.updateQueryData(
            "fetchUserAddresses",
            userId,
            (draft) => {
              return draft.filter((address) => address.id !== addressId);
            },
          ),
        );

        try {
          await queryFulfilled;
        } catch (error) {
          patchResult.undo();
        }
      },
    }),
  }),
});

export const { useCreateAddressMutation, useDeleteAddressMutation } =
  addressSearchMutationsApi;
