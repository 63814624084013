import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Session } from "@supabase/supabase-js";

interface InitialState {
  session: Session | null;
}

const initialState: InitialState = {
  session: null,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setSession: (state, action: PayloadAction<InitialState["session"]>) => {
      state.session = action.payload;
    },
  },
});

export const { setSession } = userSlice.actions;
export default userSlice.reducer;
