import { useEffect, useState } from "react";

/**
 * useArrowKeyNavigation
 * Custom hook that provides arrow key navigation for a list of items, with optional selection on Enter key press.
 *
 * @param isOpen - Boolean indicating if navigation should be active.
 * @param items - Array of items to navigate through.
 * @param onSelect - Callback function to call when an item is selected (Enter key).
 *
 * @returns An object containing:
 * - `focusedIndex`: Index of the currently focused item.
 * - `setFocusedIndex`: Setter for manually adjusting the focused index.
 *
 * The hook listens for "keydown" events when `isOpen` is true, enabling:
 * - "ArrowDown": Moves focus to the next item, wrapping to the first item if at the end.
 * - "ArrowUp": Moves focus to the previous item, wrapping to the last item if at the beginning.
 * - "Enter": Calls `onSelect` with the currently focused item.
 */

function useArrowKeyNavigation<T>({
  isOpen,
  items,
  onSelect,
}: {
  isOpen: boolean;
  items: T[];
  onSelect: (item: T) => void;
}) {
  const [focusedIndex, setFocusedIndex] = useState<number>(-1);

  useEffect(() => {
    function handleKeyDown(event: KeyboardEvent) {
      if (!isOpen || items.length === 0) return;

      switch (event.key) {
        case "ArrowDown":
          event.preventDefault();
          setFocusedIndex((prevIndex) =>
            prevIndex < items.length - 1 ? prevIndex + 1 : 0,
          );
          break;
        case "ArrowUp":
          event.preventDefault();
          setFocusedIndex((prevIndex) =>
            prevIndex > 0 ? prevIndex - 1 : items.length - 1,
          );
          break;
        case "Enter":
          event.preventDefault();
          if (focusedIndex >= 0 && focusedIndex < items.length) {
            onSelect(items[focusedIndex]);
          }
          break;
        default:
          break;
      }
    }

    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [isOpen, items, focusedIndex, onSelect]);

  return { focusedIndex, setFocusedIndex };
}

export default useArrowKeyNavigation;
