import { Map, Marker } from "@vis.gl/react-google-maps";
import { twMerge } from "tailwind-merge";
import { useEffect, useState } from "react";
import { MapCameraChangedEvent } from "@vis.gl/react-google-maps";

interface Props {
  className?: string;
  isLoading?: boolean;
  coordinates:
    | {
        lat: number;
        lng: number;
      }
    | undefined;
}

const SearchMap = ({ className, coordinates, isLoading }: Props) => {
  const cordsExist = !!coordinates;
  const [zoom, setZoom] = useState(16);

  // California coords
  const californiaCoords = {
    lat: 33.7783232,
    lng: -119.4179321,
  };

  const coordsValue = cordsExist ? coordinates : californiaCoords;

  const handleZoomChanged = (map: MapCameraChangedEvent) => {
    setZoom(map.detail.zoom);
  };

  useEffect(() => {
    setZoom(coordinates ? 16 : 6);
  }, [coordinates]);

  // Validation function for coordinates
  const isValidCoordinates = (
    coordinates: { lat: number; lng: number } | undefined,
  ) => {
    if (!coordinates) return false;

    const { lat, lng } = coordsValue;
    const isValidLat = lat >= -90 && lat <= 90;
    const isValidLng = lng >= -180 && lng <= 180;

    return isValidLat && isValidLng;
  };

  const mapKey = `${coordsValue?.lat ?? 0}-${coordsValue?.lng ?? 0}`;

  if (isLoading)
    return (
      <div className="h-[360px] w-full max-w-[462px] overflow-clip rounded-[10px] bg-rf-light-gray shadow-[0px_0px_10px_0px_#00000040] lg:max-w-[556px]"></div>
    );

  return (
    coordsValue &&
    isValidCoordinates(coordsValue) && (
      <Map
        className={twMerge(
          "h-[360px] w-full max-w-[462px] overflow-clip rounded-[10px] bg-rf-light-gray shadow-[0px_0px_10px_0px_#00000040] lg:max-w-[556px]",
          className,
        )}
        key={mapKey}
        defaultCenter={coordsValue}
        zoom={cordsExist ? zoom : 6}
        zoomControl={cordsExist}
        onZoomChanged={(map) => handleZoomChanged(map)}
        mapTypeControl={false} // Disable map type control (satellite view)
        streetViewControl={false} // Disable street view control
        fullscreenControl={cordsExist} // Disable fullscreen control
        // onTilesLoaded={handleTilesLoaded} // Trigger when tiles are loaded
        gestureHandling={cordsExist ? "" : "none"} // Disable panning
      >
        {cordsExist && <Marker position={coordsValue} />}
      </Map>
    )
  );
};

export default SearchMap;
