"use client";

import { twMerge } from "tailwind-merge";

type Props = {
  children: JSX.Element;
  className?: string;
  leftCol?: JSX.Element;
  rightCol?: JSX.Element;
};

export default function ThreeColumnLayout({
  children,
  className,
  leftCol,
  rightCol,
}: Props) {
  return (
    <div
      className={twMerge(
        `${className}`,
        "mx-auto grid w-full gap-3 lg:w-fit",
        leftCol &&
          !rightCol &&
          "lg:grid-cols-[minmax(200px,_250px)_minmax(300px,_1200px)] 2xl:grid-cols-[minmax(200px,_250px)_minmax(300px,_1200px)]",
        rightCol &&
          !leftCol &&
          "lg:grid-cols-[minmax(300px,_1200px)_minmax(200px,_200px)] 2xl:grid-cols-[minmax(300px,_1200px)_minmax(200px,_250px)]",
        leftCol &&
          rightCol &&
          "lg:grid-cols-[minmax(200px,_200px)_minmax(300px,_1200px)] 3xl:grid-cols-[minmax(200px,_250px)_minmax(300px,_1200px)_minmax(200px,_250px)]",
        !leftCol && !rightCol && "grid-cols-[minmax(300px,_1200px)]",
      )}
    >
      {/* First column (Left sidebar) */}
      {leftCol ? (
        <div className="hidden h-full lg:block">{leftCol}</div>
      ) : undefined}

      {/* Second column (Main content) */}
      <div className="h-full">{children}</div>

      {/* Third column least important (Right sidebar) */}
      {rightCol ? (
        <div
          className={twMerge(
            "hidden h-full",
            leftCol ? "2xl:block" : "lg:block",
          )}
        >
          {rightCol}
        </div>
      ) : undefined}
    </div>
  );
}
