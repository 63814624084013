import {
  ArrowSortUpRegular,
  ArrowClockwiseRegular,
  SpinnerIosRegular,
} from "@fluentui/react-icons";
import { twMerge } from "tailwind-merge";
import { useAppSelector } from "../../../app/hooks";

type Props = {
  address: string | undefined;
  coordinates: { lat: number; lng: number } | undefined;
};

export default function SubmitButton({ address, coordinates }: Props) {
  const { isLoading, hasReportBeenGenerated } = useAppSelector(
    (state) => state.addressSearch,
  );

  return (
    <button
      type="submit"
      disabled={isLoading || !address || address?.length === 0 || !coordinates}
      className={twMerge(
        "body-font flex h-[60px] w-full items-center justify-between rounded-full bg-rf-very-bright-red px-3 font-bold text-white shadow-[0px_0px_10px_0px_#F8481D80] duration-300 hover:bg-rf-vibrant-red active:bg-rf-very-bright-red disabled:opacity-75",
      )}
    >
      {/* palceholder + keeps everything aligned */}
      <ArrowSortUpRegular height={30} className="size-9 opacity-0" />

      {isLoading && <p>Generating Report...</p>}

      {!isLoading &&
        (!hasReportBeenGenerated ? (
          <p>Generate Report</p>
        ) : (
          <p>Regenerate Report</p>
        ))}

      {isLoading && (
        <SpinnerIosRegular className="size-9 animate-spin opacity-50" />
      )}

      {!isLoading &&
        (!hasReportBeenGenerated ? (
          <ArrowSortUpRegular className="size-9 opacity-50" />
        ) : (
          <ArrowClockwiseRegular className="size-9 opacity-50" />
        ))}
    </button>
  );
}
