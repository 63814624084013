import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface InitialState {
  width: number;
  breakpoints: {
    sm: boolean;
    md: boolean;
    lg: boolean;
    xl: boolean;
    "2xl": boolean;
  };
}

const initialState: InitialState = {
  width: window.innerWidth,
  breakpoints: {
    sm: false,
    md: false,
    lg: false,
    xl: false,
    "2xl": false,
  },
};

const setBreakpoints = (width: number) => ({
  sm: width >= 640,
  md: width >= 768,
  lg: width >= 1024,
  xl: width >= 1280,
  "2xl": width >= 1536,
});


const screenSlice = createSlice({
  name: "screen",
  initialState,
  reducers: {
    setScreenWidth: (state, action: PayloadAction<number>) => {
      state.width = action.payload;
      state.breakpoints = setBreakpoints(action.payload);
    },
  },
});

export const { setScreenWidth } = screenSlice.actions;
export default screenSlice.reducer;
