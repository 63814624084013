import React, { useState } from "react";
import { twMerge } from "tailwind-merge";

type Props = {
  placeholder?: string;
  value: string;
  onChange: (value: string) => void;
  className?: string;
  roundBottom?: boolean;
  onFocus?: () => void;
  onBlur?: () => void;

  disabled?: boolean;
  formSubmitOnEnter?: boolean;
};

export default function TextArea({
  placeholder = "Enter text here",
  value,
  onChange,
  className,
  onBlur,
  roundBottom = true,
  disabled = false,
  onFocus,
  formSubmitOnEnter=false
}: Props) {
  const [rows, setRows] = useState(1);

  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const textareaLineHeight = 24;
    const previousRows = e.target.rows;

    e.target.rows = 1;
    const currentRows = Math.floor(e.target.scrollHeight / textareaLineHeight);

    if (currentRows === previousRows) {
      e.target.rows = currentRows;
    }

    setRows(currentRows > 1 ? 2 : 1);
    onChange(e.target.value);

  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    // Check if Enter is pressed without Shift to trigger form submission
    if (e.key === "Enter" && !e.shiftKey) {
      // Prevent default Enter key behavior (which would add a new line)
      e.preventDefault();
      // Submit the form
      const form = e.currentTarget.closest("form");
      if (form) {
        form.requestSubmit(); // Trigger the form submission programmatically
      }
    }
  };

  return (
    <div
      className={twMerge(
        "searchTextAreaWrapper",
        !roundBottom && "rounded-b-none shadow-md",
        className,
      )}
    >
      <textarea
        disabled={disabled}
        onFocus={onFocus}
        onBlur={onBlur}
        placeholder={placeholder}
        className={twMerge("searchTextArea")}
        rows={rows}
        value={value}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
      />
    </div>
  );
}
