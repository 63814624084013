import { useEffect } from "react";

/**
 * useAdjustViewportHeight
 * Custom hook that adjusts the CSS `--vh` variable to match 1% of the viewport height, ensuring consistent sizing across devices.
 *
 * - Calculates 1vh in pixels and sets it as a CSS variable `--vh`, enabling elements to use `var(--vh)` for responsive height.
 * - Runs on initial load and recalculates the height on window resize to adapt to viewport changes (e.g., mobile address bars).
 *
 * @remarks
 * - Useful for fixing viewport height issues on mobile devices where the viewport size can change dynamically.
 * - The resize event listener is removed on unmount to prevent memory leaks.
 *
 * This hook provides a way to use viewport-relative units (`vh`) reliably across devices by maintaining the `--vh` CSS variable.
 */

function useAdjustViewportHeight() {
  useEffect(() => {
    const setViewportHeight = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    };

    setViewportHeight();

    window.addEventListener("resize", setViewportHeight);

    return () => window.removeEventListener("resize", setViewportHeight);
  }, []);
}

export default useAdjustViewportHeight;
