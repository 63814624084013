import Drawer from "../../../components/ui/Drawer/Drawer";
import DrawerFullScreen from "../../../components/ui/Drawer/DrawerFullScreen";
import { useAppDispatch } from "../../../app/hooks";
import { openModal, closeModal } from "../../modalManager/modalSlice";
import { Location20Regular } from "@fluentui/react-icons";

export default function ReportFormDrawer() {
  const dispatch = useAppDispatch();

  const drawerOpenSide = "left";

  const handleOpenSavedAddresses = () => {
    dispatch(openModal({ modalType: "SavedAddresses" }));
  };

  const DrawerContent = (
    <div>
      <button
        onClick={handleOpenSavedAddresses}
        className="body-font flex w-full items-center gap-1 rounded-md p-1.5 text-white duration-300 hover:bg-white hover:bg-opacity-10"
      >
        <Location20Regular className="mb-0.5" /> <p>My Addresses</p>
      </button>
    </div>
  );

  return (
    <>
      <div className="hidden h-full w-fit sm:block">
        <Drawer isOpen={false} side={drawerOpenSide}>
          {DrawerContent}
        </Drawer>
      </div>
      <div className="absolute block sm:hidden">
        <DrawerFullScreen side={drawerOpenSide}>
          {DrawerContent}
        </DrawerFullScreen>
      </div>
    </>
  );
}
