import { useEffect, useState } from "react";
import { SectionInfoType } from "../../../global/types";

// returns id of section in view

export function useActiveSection(sections: SectionInfoType[]) {
  const [activeSectionId, setActiveSectionId] = useState<string | null>(null);

  const calculateClosestToTop = () => {
    let closestId: string | null = null;
    let closestDistance = Infinity;

    // Check each section and determine which is closest to the viewport top
    sections.forEach((section) => {
      const element = document.getElementById(section.id);
      if (!element) return;

      const rect = element.getBoundingClientRect();
      const distanceFromTop = Math.abs(rect.top); // Distance from viewport top

      if (rect.top >= 0 && distanceFromTop < closestDistance) {
        closestDistance = distanceFromTop;
        closestId = section.id;
      }
    });

    setActiveSectionId(closestId);
  };

  useEffect(() => {
    const handleScroll = () => {
      calculateClosestToTop();
    };

    window.addEventListener("scroll", handleScroll);
    calculateClosestToTop(); // Initial check on mount

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [sections]);

  return activeSectionId;
}
