import { twMerge } from "tailwind-merge";
import { capitalizeKey } from "../../../helpers/capitalizeKey";
import React from "react";

export type KeyValueContentType = Array<{ [key: string]: string | string[] }>;

type Props = {
  content: KeyValueContentType;
  className?: string;
  keyHeader?: string;
  valueHeader?: string;
  keysBold?: boolean;
};

export default function KeyValueSection({
  content,
  className,
  keyHeader,
  valueHeader,
  keysBold,
}: Props) {
  return (
    <div className={twMerge("w-full", className)}>
      <div
        className="grid gap-x-3"
        style={{ gridTemplateColumns: "fit-content(50%) 1fr" }}
      >
        {/* Headers */}
        {keyHeader && valueHeader && (
          <>
            <p className="body-font font-bold">{keyHeader}</p>
            <p className="body-font font-bold">{valueHeader}</p>
          </>
        )}
        {/* Content Rows */}
        {content.map((item, index) => {
          const keys = Object.keys(item);
          let values: string[][];

          if (Array.isArray(Object.values(item))) {
            values = Object.values(item) as string[][];
          } else {
            values = [Object.values(item) as string[]];
          }

          return keys.map((key, idx) => (
            <React.Fragment key={`${index}-${key}`}>
              {/* Key */}
              <p
                className={`body-font line-clamp-1 break-all sm:min-w-[274px] ${keysBold ? "font-bold" : ""}`}
              >
                {capitalizeKey(key)}
              </p>

              {/* Value */}
              <div className="thin-scrollbar max-h-[129px] overflow-y-auto sm:max-h-[136px] lg:max-h-[144px]">
                {Array.isArray(values[idx]) ? (
                  values[idx].map((val, i) => (
                    <p
                      className={`body-font line-clamp-3 break-words ${values[idx].length !== i + 1 && "mb-1.5"}`}
                      key={i}
                    >
                      {val}
                    </p>
                  ))
                ) : (
                  <p className="body-font line-clamp-3 break-words">
                    {values[idx]}
                  </p>
                )}
              </div>
            </React.Fragment>
          ));
        })}
      </div>
    </div>
  );
}
