import React from "react";
import AccordionSkeleton from "../../../components/ui/loaders/AccordionSkeleton";
import ListDetailsSkeleton from "../../../components/ui/loaders/ListDetailsSkeleton";

export default function RelevantMotionsSkeleton() {
  return (
    <AccordionSkeleton title="Relevant Motions">
      <ListDetailsSkeleton />
    </AccordionSkeleton>
  );
}
