import React, {useState, useEffect} from "react";
import {ChatAppResponse, supabase} from "../../api";
import AnswerToUserQuestion from "../../features/AnswerToUserQuestion/AnswerToUserQuestion";
import {EndpointOutputs} from "../../global/types";
import {JsonViewer} from "./JsonViewer";
import ReportSection from "../ui/ReportSection/ReportSection";
import {useParams} from "react-router-dom";
import {AnswerElements} from "../../features/AnswerToUserQuestion/util/AnswerToElements";

interface StoredAnswer {
  id: number;
  answer: any;
}

interface AddressSearchState {
  isLoading: boolean;
  endpointOutputs: EndpointOutputs;
}

interface StoredReportViewProps {
  propAnswers?: StoredAnswer[],
}

function StoredReportView({propAnswers}: StoredReportViewProps) {
  const [answers, setAnswers] = useState<StoredAnswer[]>(propAnswers ?? []);
  const {id} = useParams<{ id: string }>();
  const [address, setAddress] = useState('');
  const [queries, setQueries] = useState<string[]>([]);

  useEffect(() => {
    if (propAnswers && !propAnswers.length) {
      return;
    }
    const fetchAnswers = async () => {
      if (propAnswers && propAnswers.length) {
        return;
      }
      try {
        const {data, error} = await supabase
          .from('answers')
          .select(`*,
              reports(*, stored_addresses(*)),
              stored_searches(*)
              `)
          .eq('report', id);
        if (error) throw error;

        if (data) {
          const formattedAnswers: StoredAnswer[] = data.map((item: any) => ({
            id: item.id,
            answer: item.answer,
          }));
          setAnswers(formattedAnswers);
          setAddress(data[0]?.reports?.stored_addresses?.address ?? undefined);
          setQueries(data.map((item: any) => item?.stored_searches?.query ?? undefined));
        }
      } catch (error) {
        console.error("Error fetching answers:", error);
      }
    };

    fetchAnswers();
  }, []);


  // Convert this to a fake state to preserve the answer component logic
  let toMessage = (answer: string | AnswerElements): AddressSearchState => {
    return {
      endpointOutputs:
        {
          selectedAnswer: 0,
          parkingAnswer:
            [],
          signageAnswer:
            [],
          setbackAnswer:
            [],
          streamedAnswers:
            [],
          userQueryAnswer:
            [['', {
              choices: [
                {
                  message: {
                    content: typeof answer === 'string' ? answer : JSON.stringify(answer),
                    role: "assistant"
                  },
                  index: 0,
                  context: {
                    data_points: [],
                    followup_questions: null,
                    thoughts: []
                  },
                  session_state: undefined
                }
              ]
            }]],
          permitTimeline:
          undefined,
          siteInfoResponse:
          undefined,
          motionsResponse:
          undefined
        }
      , isLoading: false
    }
  }

  return (
    <>
      {!propAnswers && address && (
        <p className="text-[28px] header-font text-center py-2">
          {address}
        </p>
      )}
      {answers.map((answer, i) => (
        <div key={i}>
          <AnswerToUserQuestion completeAnswer={toMessage(answer.answer)} title={queries[i]}/>
          <ReportSection collapsible title={"Raw Data (JSON)"} isInitiallyOpen={false}>
            <JsonViewer answers={[answers[i]]}/>
          </ReportSection>
        </div>
      ))}
    </>
  );
}

export default StoredReportView;
