import {createClient} from '@supabase/supabase-js'

const supabaseUrl = 'https://yyryegltkzifcktgbkqq.supabase.co'
const supabaseAnonKey = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Inl5cnllZ2x0a3ppZmNrdGdia3FxIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTg4Mzk1OTQsImV4cCI6MjAzNDQxNTU5NH0.eVGkmW-JFkSLIsJ8J0K5-KL9N3nj433vezOPob68ozk"
export const supabase = createClient(supabaseUrl, supabaseAnonKey)

export interface User {
    id: string  ;
    first_name: string | null;
    last_name: string | null;
    organization: string | null;
}

export interface SubscriptionData {
    id: string;
    created_at: string;  // Timestamps are usually represented as strings
    expiration: string;  // This can be nullable
    user_id: string;
}
