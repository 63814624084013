type CollapsibleSectionProps = {
  title: string | React.ReactNode;
  children: React.ReactNode;
};

export default function CollapsibleSection({
  title,
  children,
}: CollapsibleSectionProps) {
  return (
    <div className="w-full max-w-[462px] grow basis-1 lg:max-w-[250px]">
      <p className="desc-font mb-3 block font-bold tracking-tight min-h-fit lg:min-h-[42px]">{title} </p>

      <div
        className="flex h-fit flex-col gap-1.5 rounded-md border border-rf-dark-brown px-3 py-2"
        role="group"
      >
        {children}
      </div>
    </div>
  );
}
