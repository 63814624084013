// src/components/ChartComponent.tsx
import React, { useMemo } from "react";
import Chart from "react-apexcharts";

interface DataEntry {
  min: number;
  q1: number;
  median: number;
  q3: number;
  max: number;
}

interface Props {
  boxplots?: Map<string, DataEntry>;
  horizontal?: boolean;
  medianTicker?: number;
  tickerText?: string;
}

export default function BoxPlot({
  boxplots,
  horizontal = false,
  medianTicker = undefined,
  tickerText = undefined,
}: Props) {
  if (!boxplots) return <></>;

  const series = useMemo(() => {
    return [
      {
        name: "box",
        type: "boxPlot",
        data: Object.entries(boxplots).map(([category, values]) => ({
          x: category,
          y: [
            Math.floor(values.min),
            values.q1,
            values.median,
            values.q3,
            Math.ceil(values.max),
          ].map((n) => Math.round(n)),
        })),
      },
    ];
  }, [boxplots]);

  const options = useMemo(() => {
    return {
      chart: {
        toolbar: { show: false }, // Disable toolbar for performance
        animations: { enabled: false }, // Disable animations for performance
      },
      plotOptions: {
        boxPlot: {
          colors: { upper: "#ec830b", lower: "#ad4400" },
        },
        bar: { horizontal, barHeight: "50%" },
      },
      tooltip: {
        y: {
          formatter: (val: number) => Number(val.toFixed(2)), // Format tooltip to 2 decimal places
        },
      },
      annotations: medianTicker
        ? {
            xaxis: [
              {
                x: medianTicker,
                borderColor: "#0e8be0",
                borderWidth: 1.5,
                opacity: 1,
                strokeDashArray: 0,
                label: {
                  style: { color: "#0da0d0" },
                  text: tickerText ?? "Median",
                },
              },
            ],
          }
        : {},
      xaxis: { title: { text: "Approval Time (Weeks)" } },
    };
  }, [horizontal, medianTicker, tickerText]);

  return (
    <div className="chart">
      <br />
      {/* @ts-ignore */}
      <Chart series={series} options={options} type="boxPlot" width="90%" />
    </div>
  );
}
