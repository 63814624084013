import { FC, FormEvent, useState } from 'react';
import { supabase } from './api/db';

const Auth: FC = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [email, setEmail] = useState<string>('');

    const handleLogin = async (event: FormEvent) => {
        event.preventDefault();

        setLoading(true);
        const { error } = await supabase.auth.signInWithOtp({ email });

        if (error) {
            alert(error.message);
        } else {
            alert('Check your email for the login link!');
        }
        setLoading(false);
    };

    return (
        <div className="row flex flex-center">
            <div className="col-6 form-widget">
                <h1 className="header">Supabase + React</h1>
                <p className="description">
                    Sign in via magic link with your email below
                </p>
                <form onSubmit={handleLogin}>
                    <div>
                        <input
                            className="inputField"
                            type="email"
                            placeholder="Your email"
                            value={email}
                            required
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                    <div>
                        <button className="button block" disabled={loading}>
                            {loading ? (
                                <span>Loading</span>
                            ) : (
                                <span>Send magic link</span>
                            )}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default Auth;
