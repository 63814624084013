// useAnnotationNavigation.ts
import { useImperativeHandle, useEffect, Ref } from "react";
import type { Dispatch, SetStateAction, MutableRefObject } from "react";
import type { IHighlight } from "react-pdf-highlighter";
import { DocumentRendererHandle } from "../components/DocumentRenderer/DocumentRenderer";

export const useAnnotationNavigation = (
  ref: Ref<DocumentRendererHandle>,
  highlights: Array<Array<IHighlight>>,
  references: MutableRefObject<{
    [key: string]: {
      filename: string;
      highlights: IHighlight[];
    };
  }>,
  setUrl: Dispatch<SetStateAction<string>>,
  scrollViewerTo: MutableRefObject<(highlight: IHighlight) => void>,
) => {
  const parseIdFromHash = (): string => {
    return window.location.hash.slice("#highlight-".length);
  };

  const resetHash = (): void => {
    // window.location.hash = "";
  };

  const getAnnotationIndex = (annotation: string): number => {
    const idx = highlights.findIndex((h) => h[0].id === annotation);
    return idx;
  };

  const gotoAnnotation = (
    annotation: string,
    delayScroll: boolean = false,
  ): void => {
    if (!annotation) return;

    const refAnnotationId = annotation.split("=").pop()!;
    const annotationValue = references.current[refAnnotationId];
    if (!annotationValue) return;
    const { filename } = annotationValue;

    const activeHighlightIndex = getAnnotationIndex(annotation);

    setUrl(filename);

    if (delayScroll) {
      setTimeout(() => {
        if (scrollViewerTo.current && highlights[activeHighlightIndex]) {
          scrollViewerTo.current(highlights[activeHighlightIndex][0]);
        }
      }, 500);
    } else {
      if (
        scrollViewerTo.current &&
        highlights[activeHighlightIndex] &&
        highlights[activeHighlightIndex][0]
      ) {
        scrollViewerTo.current(highlights[activeHighlightIndex][0]);
      } else {
        gotoAnnotation(annotation, true);
      }
    }
  };

  const getHighlightById = (id: string): Array<IHighlight> | undefined => {
    return highlights.find((highlight) => highlight[0].id === id);
  };

  const scrollToHighlightFromHash = (): void => {
    const highlight = getHighlightById(parseIdFromHash());
    if (highlight && scrollViewerTo.current) {
      scrollViewerTo.current(highlight[0]);
    }
  };

  // Handle hash change events to navigate to highlights
  useEffect(() => {
    window.addEventListener("hashchange", scrollToHighlightFromHash, false);

    return () => {
      window.removeEventListener(
        "hashchange",
        scrollToHighlightFromHash,
        false,
      );
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [highlights]);

  // Expose methods to parent component via the ref
  useImperativeHandle(ref, () => ({
    gotoAnnotation,
  }));

  return {
    gotoAnnotation,
    resetHash,
    getHighlightById,
  };
};
