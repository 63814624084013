import { useEffect, RefObject } from "react";

/**
 * useClickOutside
 * Custom hook that triggers a callback function when a click event occurs outside the specified element,
 * with an option to ignore clicks on certain elements by their IDs.
 *
 * @param ref - A React ref object pointing to the element to detect outside clicks for.
 * @param onClickOutside - Callback function invoked when a click outside the element occurs.
 * @param ignoredIds - Optional array of element IDs to ignore clicks on.
 */

function useClickOutside<T extends HTMLElement>(
  ref: RefObject<T>,
  onClickOutside: () => void,
  ignoredIds?: string[],
) {
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      const target = event.target as HTMLElement;

      // If click is on the ref element or its descendants, do nothing
      if (ref.current && ref.current.contains(target)) {
        return;
      }

      // If click is on an element with an id in the ignoredIds, do nothing
      if (ignoredIds && ignoredIds.length > 0) {
        let currentNode: HTMLElement | null = target;
        while (currentNode) {
          if (currentNode.id && ignoredIds.includes(currentNode.id)) {
            return; // Ignore the click
          }
          currentNode = currentNode.parentElement;
        }
      }

      // Otherwise, trigger the callback
      onClickOutside();
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, onClickOutside, ignoredIds]);
}

export default useClickOutside;
