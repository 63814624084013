import React from "react";
import ListDetailsSkeleton from "../../../components/ui/loaders/ListDetailsSkeleton";
import AccordionSkeleton from "../../../components/ui/loaders/AccordionSkeleton";
import ReportSectionSkeleton from "../../../components/ui/loaders/ReportSectionSkeleton";
import TextSkeleton from "../../../components/ui/loaders/TextSkeleton";

export default function AnswerToUserQuestionSkeleton() {
  return (
    <AccordionSkeleton title="Answer to User Question">
      <ReportSectionSkeleton title>
        <TextSkeleton lines={5} />
      </ReportSectionSkeleton>
      <ListDetailsSkeleton />
    </AccordionSkeleton>
  );
}
