import {AddressLookupResponse} from "../../../api";
import {ParsedAnnotation} from "../../../components/Answer";
import {parseAnswerToHtml} from "../../../components/Answer/AnswerParser";

// New interface for the return type
export interface AnswerElements {
  parsedAnswerElements: {
    citations: string[];
    answerElements: JSX.Element[];
  };
  sources: ParsedAnnotation[];
  additionalSources: ParsedAnnotation[];
}

export function answerToElements(isLoading: boolean, messageContent: string, navigateToCitation: (annotation: string) => void): AnswerElements {
  let addressLookupResp: AddressLookupResponse | undefined;
  let parsedAnswerElementsResult: {
    citations: string[];
    answerElements: JSX.Element[];
  } = {citations: [], answerElements: []}; // Initialize with default values
  let sourcesResult: ParsedAnnotation[] = [];
  let additionalSourcesResult: ParsedAnnotation[] = [];

  if (!isLoading) {
    try {
      addressLookupResp = JSON.parse(messageContent);
      addressLookupResp = addressLookupResp!;
      parsedAnswerElementsResult = parseAnswerToHtml(
        JSON.stringify(addressLookupResp["answer"]),
        false,
        navigateToCitation,
      );

      if (addressLookupResp["sources"] != undefined) {
        addressLookupResp["sources"].forEach((source) => {
          sourcesResult.push(new ParsedAnnotation(source));
        });
      }
      if (addressLookupResp["additional_sources"] != undefined) {
        addressLookupResp["additional_sources"].forEach((source) => {
          additionalSourcesResult.push(new ParsedAnnotation(source));
        });
      }
    } catch (e) {
      console.error("Error parsing message content:", e);
      // Handle the error, maybe log it
      parsedAnswerElementsResult = parseAnswerToHtml(
        JSON.stringify(messageContent),
        false,
        navigateToCitation,
      );
    }
  } else {
    parsedAnswerElementsResult = parseAnswerToHtml(
      "",
      false,
      navigateToCitation,
    );
  }

  return {
    parsedAnswerElements: parsedAnswerElementsResult,
    sources: sourcesResult,
    additionalSources: additionalSourcesResult,
  };
}
