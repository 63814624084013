// utils/deviceDetection.ts

export type DeviceName =
  | "Android"
  | "iPhone"
  | "iPad"
  | "iPod"
  | "Windows Phone"
  | "BlackBerry"
  | "webOS";

export const isMobile = (): boolean => {
  if (typeof navigator === "undefined") return false;
  return /Mobi|Android|iPhone|iPad|iPod|Windows Phone/i.test(
    navigator.userAgent,
  );
};

export const isAndroid = (): boolean => {
  if (typeof navigator === "undefined") return false;
  return /Android/i.test(navigator.userAgent);
};

export const isiPhone = (): boolean => {
  if (typeof navigator === "undefined") return false;
  return /iPhone/i.test(navigator.userAgent);
};

export const isDevice = (deviceName: DeviceName): boolean => {
  if (typeof navigator === "undefined") return false;
  const regex = new RegExp(deviceName, "i");
  return regex.test(navigator.userAgent);
};
