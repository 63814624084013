// add bold to matched string

export const highlightMatch = (text: string, match: string) => {
  // Normalize both text and match by removing all non-alphanumeric characters and converting to lowercase
  const normalizeString = (str: string) =>
    str.replace(/[^a-zA-Z0-9]/g, "").toLowerCase();

  const normalizedText = normalizeString(text);
  const normalizedMatch = normalizeString(match);

  // Find the index of the normalized match in the normalized text
  const matchIndex = normalizedText.indexOf(normalizedMatch);

  if (matchIndex === -1) {
    return text; // If no match, return the original text
  }

  // Map the match index back to the original text with spaces and special characters
  let currentIndex = 0;
  let startIndex = -1;
  let endIndex = -1;

  for (let i = 0; i < text.length; i++) {
    if (/[a-zA-Z0-9]/.test(text[i])) {
      if (currentIndex === matchIndex) {
        startIndex = i;
      }
      if (currentIndex === matchIndex + normalizedMatch.length - 1) {
        endIndex = i + 1;
        break;
      }
      currentIndex++;
    }
  }

  const beforeMatch = text.substring(0, startIndex);
  const matchingText = text.substring(startIndex, endIndex);
  const afterMatch = text.substring(endIndex);

  return (
    <>
      {beforeMatch}
      <span className="font-semibold">{matchingText}</span>
      {afterMatch}
    </>
  );
};
