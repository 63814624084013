import { useEffect } from "react";
import { supabase } from "../../api";
import { useAppDispatch } from "../../app/hooks";
import { setSession } from "../../app/globals/userSlice";
//  Custom hook that manages and returns the current Supabase authentication session and sets it in userSlice.

function useSupabaseSession() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      dispatch(setSession(session));
    });

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      dispatch(setSession(session));
    });

    return () => subscription.unsubscribe();
  }, []);
}

export default useSupabaseSession;
