import React, { useEffect, useState } from 'react';
import { UserSubscriptionCrud } from "./UserSubscriptionCrud";
import { User } from "../../../api/db";
import UserSubscriptionForm from "./UserAdminForm";
import { DetailsList, DetailsListLayoutMode, SelectionMode } from '@fluentui/react';

const UserTable: React.FC = () => {
    const [users, setUsers] = useState<User[]>([]);
    const [selectedUserId, setSelectedUserId] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    const crud = new UserSubscriptionCrud();

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                setLoading(true);
                const fetchedUsers = await crud.getAllAuthUsers();
                setUsers(fetchedUsers || []);
            } catch (err) {
                setError('Failed to load users');
            } finally {
                setLoading(false);
            }
        };
        fetchUsers();
    }, []);

    const handleUserSelect = (user: User) => {
        setSelectedUserId(user.id);
    };

    const columns = [
        { key: 'firstName', name: 'First Name', fieldName: 'first_name', minWidth: 100, maxWidth: 200, isResizable: true },
        { key: 'lastName', name: 'Last Name', fieldName: 'last_name', minWidth: 100, maxWidth: 200, isResizable: true },
        { key: 'organization', name: 'Organization', fieldName: 'organization', minWidth: 100, maxWidth: 200, isResizable: true },
    ];

    if (loading) return <p>Loading users...</p>;
    if (error) return <p>{error}</p>;

    return (
        <div>
            <h1 className={"text-center text-lg p-2"}> User List</h1>
            <DetailsList
                items={users}
                columns={columns}
                layoutMode={DetailsListLayoutMode.justified}
                selectionMode={SelectionMode.single}
                onActiveItemChanged={handleUserSelect}
            />

            {selectedUserId && (
                <div style={{ marginTop: '20px' }}>
                    <UserSubscriptionForm userId={selectedUserId} />
                </div>
            )}
        </div>
    );
};

export default UserTable;
