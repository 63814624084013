import React, { useState } from "react";
import ReportSection from "../ReportSection/ReportSection";
import Dropdown from "../../forms/Dropdown/Dropdown";
import ListButton from "../Buttons/ListButton";
import Spinner from "../loaders/Spinner";

export type ListItemType = {
  icon?: React.ReactNode;
  desc: string;
  citation?: string;
  counter?: string;
  onClick?: () => any;
};

export type ListSectionType = {
  title?: string;
  items: ListItemType[];
};

type Props = {
  title?: string;
  children: React.ReactNode;
  collapsible?: boolean;
  className?: string;
  list: ListSectionType[];
  scrollableTarget?: string;
  loadingDetails?: boolean;
  errorDetails?: string | null;
};
export default function ListDetails({
  title,
  children,
  collapsible,
  className,
  list,
  scrollableTarget = "",
  loadingDetails,
  errorDetails,
}: Props) {
  const [selectedIndex, setSelectedIndex] = useState("0-0");

  const handleSetIndex = (index: string) => {
    setSelectedIndex(index);
  };

  return (
    <ReportSection
      title={title}
      collapsible={collapsible}
      className={className}
      childPadding={false}
    >
      <div className="flex flex-col gap-1.5 rounded-b-md bg-rf-very-light-gray pt-5 md:flex-row">
        <div className="thin-scrollbar relative mb-3 hidden max-h-[80vh] min-h-[600px] w-full basis-[100%] overflow-y-scroll px-2.5 md:block md:max-w-[300px] lg:max-w-[340px]">
          <hr className="line-break absolute left-2.5 top-0 my-0 w-[calc(100%-20px)]" />
          {list.map((section, i) => (
            <div key={i}>
              {section.title && section.items.length > 0 && (
                <>
                  <h3 className="body-font my-3 mt-3.5 line-clamp-1 overflow-hidden break-all px-3 font-semibold md:px-[18px] lg:px-6">
                    {section.title}
                  </h3>
                  <hr className="line-break my-0" />
                </>
              )}
              {section.items.map((item, index) => (
                <ListButton
                  isLoading={loadingDetails}
                  handleSetIndex={handleSetIndex}
                  index={`${i}-${index}`}
                  selectedIndex={selectedIndex}
                  {...item}
                  key={`${i}-${index}`}
                />
              ))}
            </div>
          ))}
        </div>

        <div className="relative block px-3 md:hidden">
          <Dropdown
            isLoading={loadingDetails}
            selectedIndex={selectedIndex}
            list={list}
            handleSetIndex={handleSetIndex}
          />
        </div>

        <div className="relative h-[700px] md:h-auto md:flex-1">
          <div className="absolute inset-0 m-3 ml-0 mt-0 border-b-[1px] border-r-[1px] border-rf-light-gray pl-[18px] pr-0 shadow-[0px_0px_20px_5px_#00000026_inset]">
            <div
              id={scrollableTarget}
              className="thin-scrollbar mr-1.5 flex h-full max-h-full flex-col overflow-y-scroll pr-1.5"
            >
              {loadingDetails && (
                <div className="flex grow items-center justify-center">
                  <Spinner className="mx-auto block" />
                </div>
              )}

              {errorDetails && !loadingDetails && (
                <p className="body-font flex h-full grow items-center justify-center">
                  {errorDetails}
                </p>
              )}

              {!loadingDetails && !errorDetails && children}
            </div>
          </div>
        </div>
      </div>
    </ReportSection>
  );
}
