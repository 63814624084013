import React from "react";
import { twMerge } from "tailwind-merge";
import { PlacementType } from "../../../global/types";

type Props = {
  placement: PlacementType;
  position?: "fixed" | "absolute";
  children: React.ReactNode;
  className?: string;
};
export default function FixedPositionWrapper({
  placement,
  position = "fixed",
  children,
  className,
}: Props) {
  const positionClasses = {
    "top-left": `top-1.5 left-1.5 lg:top-3 lg:left-3 xl:top-10 xl:left-10`,
    "top-right": `top-1.5 right-1.5 lg:top-3 lg:right-3 xl:top-10 xl:right-10`,
    "bottom-left": `bottom-1.5 left-1.5 lg:bottom-3 lg:left-3 xl:bottom-10 xl:left-10`,
    "bottom-right": `bottom-1.5 right-1.5 lg:bottom-3 lg:right-3 xl:bottom-10 xl:right-10`,
    "top-center": `top-1.5 left-1/2 transform -translate-x-1/2 lg:top-3 xl:top-10`,
    "bottom-center": `bottom-1.5 left-1/2 transform -translate-x-1/2 lg:bottom-3 xl:bottom-10`,
    "left-center": `top-1/2 left-1.5 transform -translate-y-1/2 lg:left-3 xl:left-10`,
    "right-center": `top-1/2 right-1.5 transform -translate-y-1/2 lg:right-3 xl:right-10`,
  };

  const positionClass = positionClasses[placement];

  return (
    <div
      className={twMerge(
        "z-20",
        positionClass,
        position === "fixed" ? "fixed" : "absolute",
        className,
      )}
    >
      {children}
    </div>
  );
}
