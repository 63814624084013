import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { EndpointOutputs } from "../../global/types";
import mockData from "./mockData.json";
import { AnalysisPanelTabs } from "../../components/AnalysisPanel";
import { createSelector } from "@reduxjs/toolkit";

// Define the initial state for the slice
interface AddressSearchState {
  isLoading: boolean;
  activeCitation: string | undefined;
  activeAnalysisPanelTab: AnalysisPanelTabs | undefined;
  endpointOutputs: EndpointOutputs;
  hasReportBeenGenerated: boolean;
}

const devmode = process.env.NODE_ENV === "development";

const initialEndpointOutputs: EndpointOutputs = {
  selectedAnswer: 0,
  parkingAnswer: [],
  signageAnswer: [],
  setbackAnswer: [],
  streamedAnswers: [],
  // @ts-ignore
  userQueryAnswer: devmode ? mockData.searchResults : [],
  // @ts-ignore
  permitTimeline: devmode ? mockData.timeline : undefined,
  // @ts-ignore
  siteInfoResponse: devmode ? mockData.siteInfoResponse : undefined,
  // @ts-ignore
  motionsResponse: devmode ? mockData.address : undefined,
};

const initialState: AddressSearchState = {
  isLoading: false,
  activeCitation: undefined,
  activeAnalysisPanelTab: undefined,
  endpointOutputs: initialEndpointOutputs,
  hasReportBeenGenerated: devmode ? true : false,
};

const addressSearchSlice = createSlice({
  name: "addressSearch",
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    setActiveCitation(state, action: PayloadAction<string | undefined>) {
      state.activeCitation = action.payload;
    },
    setHasReportBeenGenerated(state, action: PayloadAction<boolean>) {
      state.hasReportBeenGenerated = action.payload;
    },
    setActiveAnalysisPanelTab(
      state,
      action: PayloadAction<AnalysisPanelTabs | undefined>,
    ) {
      state.activeAnalysisPanelTab = action.payload;
    },
    setEndpointOutputs(state, action: PayloadAction<Partial<EndpointOutputs>>) {
      state.endpointOutputs = {
        ...state.endpointOutputs,
        ...action.payload,
      };
    },
    resetAddressSearchState() {
      return initialState;
    },
  },
});

export const selectHasEndpointData = createSelector(
  (state: { addressSearch: { endpointOutputs: EndpointOutputs; }; }): EndpointOutputs => state.addressSearch.endpointOutputs,
  (endpointOutputs) => {
    const {
      userQueryAnswer,
      permitTimeline,
      siteInfoResponse,
      motionsResponse,
    } = endpointOutputs;

    const hasUserQueryAnswer =
      Array.isArray(userQueryAnswer) && userQueryAnswer.length > 0;
    const hasPermitTimeline =
      permitTimeline !== undefined && permitTimeline !== null;
    const hasSiteInfoResponse =
      siteInfoResponse !== undefined && siteInfoResponse !== null;
    const hasMotionsResponse =
      motionsResponse !== undefined && motionsResponse !== null;

    return (
      hasUserQueryAnswer ||
      hasPermitTimeline ||
      hasSiteInfoResponse ||
      hasMotionsResponse
    );
  },
);

export const {
  setLoading,
  resetAddressSearchState,
  setActiveCitation,
  setActiveAnalysisPanelTab,
  setEndpointOutputs,
  setHasReportBeenGenerated,
} = addressSearchSlice.actions;

export default addressSearchSlice.reducer;
