import { configureStore } from "@reduxjs/toolkit";

//reducers
import modalReducer from "../features/modalManager/modalSlice";
import screenReducer from "./globals/screenSlice";
import addressSearchReducer from "../features/AddressSearchSection/addressSearchSectionSlice";
import userReducer from "./globals/userSlice";

// api slices
import { motionsApi } from "../features/RelevantMotions/motionsApiSlice";
import { addressSearchApi } from "../features/AddressSearchSection/addressSearchSectionApiSlice";
import { addressSearchMutationsApi } from "../features/AddressSearchSection/AddressSearchSectionMutationsApiSlice";

export const store = configureStore({
  reducer: {
    [motionsApi.reducerPath]: motionsApi.reducer,
    [addressSearchApi.reducerPath]: addressSearchApi.reducer,
    [addressSearchMutationsApi.reducerPath]: addressSearchMutationsApi.reducer,

    modal: modalReducer,
    screen: screenReducer,
    addressSearch: addressSearchReducer,
    user: userReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      motionsApi.middleware,
      addressSearchApi.middleware,
      addressSearchMutationsApi.middleware,
    ),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
