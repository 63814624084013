// apiBaseQuery.ts
import { fetchBaseQuery } from "@reduxjs/toolkit/query/react";

// Import or define your getHeaders function
import { getHeaders } from "../../api";

const BACKEND_URI = "";

// Create a base query function
const rawBaseQuery = fetchBaseQuery({ baseUrl: BACKEND_URI });

export const baseQuery = async (args: any, api: any, extraOptions: any) => {
  // Fetch headers asynchronously
  const additionalHeaders = await getHeaders();

  // If args is a string (URL), convert it to an object
  if (typeof args === "string") {
    args = { url: args };
  }

  // Merge existing headers with additional headers
  args.headers = {
    ...(args.headers || {}),
    ...additionalHeaders,
  };

  // Call the base query with the modified args
  return rawBaseQuery(args, api, extraOptions);
};
