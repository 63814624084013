import React, {useRef, useImperativeHandle, forwardRef, createRef, useState, MutableRefObject, useEffect} from "react";

import type {
  Content,
  IHighlight,
  ScaledPosition,
} from "react-pdf-highlighter";
import {
  AreaHighlight,
  Highlight,
  PdfHighlighter,
  PdfLoader,
  Popup,
} from "react-pdf-highlighter";

import ListDetails from "../../../../components/ui/ListDetails/ListDetails";
import {ListSectionType} from "../../../../components/ui/ListDetails/ListDetails";

import {ParsedAnnotation} from "../../../../components/Answer";
import InteractionBar from "./InteractionBar";
import {useFullscreen} from "../../../../hooks/useFullscreen";
import Spinner from "../../../../components/ui/loaders/Spinner";

import {TextItem} from "pdfjs-dist/types/src/display/api";
import {useAnnotations} from "../../hooks/useAnnotations";
import {useAnnotationNavigation} from "../../hooks/useAnnotationNavigation";
import {RfHighlight} from "../../../../components/HighlightDocContent/RfHighlight";

interface Props {
  citedSources?: ParsedAnnotation[];
  additionalSources?: ParsedAnnotation[];
}

export interface DocumentRendererHandle {
  gotoAnnotation: (annotation: string, delayScroll?: boolean) => void;
}

const parseIdFromHash = () =>
  document.location.hash.slice("#highlight-".length);

const HighlightPopup = ({
  comment,
}: {
  comment: { text: string; emoji: string };
}) =>
  comment.text ? (
    <div className="Highlight__popup">
      {comment.emoji} {comment.text}
    </div>
  ) : null;

const HighlightDocContent = forwardRef<DocumentRendererHandle, Props>(
  ({citedSources, additionalSources}, ref) => {
    const {isFullScreen, toggleFullScreen, containerRef} = useFullscreen();

    const pdfHighlighterRef = useRef<PdfHighlighter<any>>(null!) as MutableRefObject<PdfHighlighter<any>>;

    let [foundMatchHighlights, setFoundMatchHighlights] = useState<IHighlight[]>([]);

    const scrollToHighlightFromHash = () => {
      const highlight = getHighlightById(parseIdFromHash());
      if (highlight && scrollViewerTo.current) {
        scrollViewerTo.current(highlight[0]);
      }
    };

    const scrollToText = ({pageNumber, position}: { pageNumber: number, position: number[] }, offset = 0) => {
      pdfHighlighterRef.current?.viewer?.scrollPageIntoView({
        pageNumber,
        destArray: [null, {name: `XYZ`}, position[4], position[5] + offset, null]
      });
    };

    const onSearchStringChanged = async (searchString: string, index: number = 0) => {
      if (searchString.length < 3) {
        setFoundMatchHighlights([])
        return
      }
      // @ts-ignore
      let pdfDocument = pdfHighlighterRef?.current?.viewer?.pdfDocument
      if (!pdfDocument) return;
      const numPages = pdfDocument.numPages;
      let match = null;

      let references: any[] = [];

      let matchHighlights: any[] = [];

      let offset = 0;

      for (let pageNumber = 1; pageNumber <= numPages; pageNumber++) {
        const page = await pdfDocument.getPage(pageNumber);
        const textContent = await page.getTextContent();

        if (!offset) {
          offset = page.getViewport().viewBox[3] * .2;
        }

        const pageText = textContent.items.map((item) => (item as TextItem).str).join(" ");
        const matchIndex = pageText.toLowerCase().indexOf(pageText.toLowerCase());

        if (matchIndex !== -1) {
          const matchingItems: TextItem[] = (textContent.items.filter((item) =>
              (item as TextItem).str.toLowerCase().includes(searchString.toLowerCase())) as TextItem[]
          );
          matchingItems.forEach((item: TextItem) => {
            match = {pageNumber, position: item.transform};
            references.push(match)
            const pageWidth = page.getViewport().viewBox[2];
            const pageHeight = page.getViewport().viewBox[3];
            let charWidth = item.width / item.str.length;
            let searchWidth = searchString.length * charWidth
            let searchOffset = charWidth * item.str.toLowerCase().indexOf(searchString.toLowerCase());

            matchHighlights.push(
              {
                id: `search-match-${pageNumber}-${matchIndex}`,
                position: {
                  boundingRect: {
                    x1: 0,
                    y1: 0,
                    x2: 1,
                    y2: 1,
                    width: 1, // todo: handle landscape docs
                    height: 1, // todo: handle landscape docs
                  },
                  rects: [{
                    x1: (item.transform[4] + searchOffset) / pageWidth,
                    y1: (page.getViewport().viewBox[3] - item.transform[5] - item.height) / pageHeight,
                    x2: ((item.transform[4] + searchOffset) + searchWidth) / pageWidth,
                    y2: (page.getViewport().viewBox[3] - (item.transform[5])) / pageHeight,
                    width: 1, // todo: handle landscape docs
                    height: 1, // todo: handle landscape docs
                  }],
                  // continue rects here
                  pageNumber: pageNumber,
                },
                content: {
                  text: "",
                },
                comment: {
                  text: '',
                  emoji: "",
                }
              });
          });
        }
      }

      if (references.length) {
        if (index < 0) {
          index += references.length;
        }
        scrollToText(references[index % references.length], offset);
      }
      setFoundMatchHighlights(matchHighlights)
    }

    const {url, highlights, references, numberOfCitations, setUrl} =
      useAnnotations({
        citedSources: citedSources,
        additionalSources: additionalSources,
        scrollToHighlightFromHash: scrollToHighlightFromHash,
      });

    const scrollViewerTo = useRef<(highlight: IHighlight) => void>(() => {
    });

    const {gotoAnnotation, resetHash, getHighlightById} =
      useAnnotationNavigation(
        ref,
        highlights,
        references,
        setUrl,
        scrollViewerTo,
      );

    // Expose methods to parent component
    useImperativeHandle(ref, () => ({
      gotoAnnotation,
    }));

    const updateHighlight = (
      highlightId: string,
      position: Partial<ScaledPosition>,
      content: Partial<Content>,
    ) => {
      // Implement updateHighlight logic here
    };

    // Variables used in rendering
    const getHighlightsInDocument = () => highlights.flat().filter(
      (highlight) =>
        highlight?.fileName && highlight.fileName.split("/").pop() === url.split("/").pop(),
    );

    const sidebarHighlights = highlights.map((highlight) => highlight[0]);
    const enumeratedAnnotations = sidebarHighlights.slice(
      0,
      numberOfCitations.current,
    )

    let orderedCited: string[] = []
    let idx = 0;

    const citedGrouped = enumeratedAnnotations.reduce((acc: Record<string, [index: number, highlight: RfHighlight][]>, item) => {
      if (item.isContinued){
        return acc;
      }
      const category: string = item.fileName ?? '';
      if (!acc[category]) {
        acc[category] = [];
        orderedCited.push(category)
      }
      acc[category].push([idx++, item]);
      return acc;
    }, {});

    let orderedAdditional: string[] = []
    const additionalAnnotations = sidebarHighlights.slice(
      numberOfCitations.current,
    );

    const additionalGrouped = additionalAnnotations.reduce((acc: Record<string, [index: number, highlight: RfHighlight][]>, item) => {
      if (item.isContinued){
        return acc;
      }
      const category: string = item.fileName ?? '';
      if (!acc[category]) {
        acc[category] = [];
        orderedAdditional.push(category)
      }
      acc[category].push([idx++, item]);
      return acc;
    }, {});

    let groupedOrderedCited = orderedCited.reduce((acc, file) => {
      return acc.concat(citedGrouped[file] || []);
    }, [] as [number, RfHighlight][]);

    let groupedOrderedAdditional = orderedAdditional.reduce((acc, file) => {
      return acc.concat(additionalGrouped[file] || []);
    }, [] as [number, RfHighlight][]);

    const listSectionOne: ListSectionType = {
      title: "Cited Sources",
      items: groupedOrderedCited
        .map(([index, highlight]) => {
          if (highlight.isContinued) return undefined;

          const highlightId = highlight.id;
          const referenceName =
            references.current[highlightId]?.filename?.split("/").pop() ||
            "Unknown Document";

          return {
            desc: `${index + 1}. ${highlight.comment.text} ${
              highlight.content.text ?? ""
            }`,
            citation: `${referenceName} Page ${highlight.position.pageNumber}`,
            onClick: () => gotoAnnotation(highlightId, false),
          };
        })
        .filter((x) => x) as ListSectionType["items"],
    };

    const listSectionTwo: ListSectionType = {
      title: "Additional Sources",
      items: groupedOrderedAdditional
        .map(([index, highlight]) => {
          if (highlight.isContinued) return undefined;

          const highlightId = highlight.id;
          const referenceName =
            references.current[highlightId]?.filename?.split("/").pop() ||
            "Unknown Document";

          return {
            desc: `${index + 1}. ${highlight.comment.text} ${
              highlight.content.text ?? ""
            }`,
            citation: `${referenceName} Page ${highlight.position.pageNumber}`,
            onClick: () => gotoAnnotation(highlightId, false),
          };
        })
        .filter((x) => x) as ListSectionType["items"],
    };

    return (
      <ListDetails
        list={[listSectionOne, listSectionTwo]}
        title="Document Search Results"
      >
        <div ref={containerRef} className="relative h-full w-full">
          <PdfLoader
            url={url}
            beforeLoad={
              <div className="flex h-full w-full items-center justify-center">
                <SpinnerWithCallback onUnmount={() => {
                  let first = getHighlightsInDocument().flat().at(0)
                  // HACK: The PDF appears simultaneously with this event. So goto next frame
                  first && setTimeout(() => gotoAnnotation(first.id), 1);}} />
              </div>
            }
          >
            {(pdfDocument) => {
              return (
                <div className="">
                  <InteractionBar
                    toggleFullScreen={toggleFullScreen}
                    isFullScreen={isFullScreen}
                    url={url}
                    onSearchInputChanged={onSearchStringChanged}
                    numSearchResults={foundMatchHighlights.length}
                  />

                  <PdfHighlighter
                    ref={pdfHighlighterRef}
                    pdfDocument={pdfDocument}
                    enableAreaSelection={(event) => event.altKey}
                    onScrollChange={resetHash}
                    pdfScaleValue="page-width"
                    scrollRef={(scrollTo) => {
                      scrollViewerTo.current = scrollTo;
                      scrollToHighlightFromHash();
                    }}
                    onSelectionFinished={() => null}
                    highlightTransform={(
                      highlight,
                      index,
                      setTip,
                      hideTip,
                      viewportToScaled,
                      screenshot,
                      isScrolledTo,
                    ) => {
                      const isTextHighlight = !highlight.content?.image;

                      const component = isTextHighlight ? (
                        <Highlight
                          isScrolledTo={false}
                          position={highlight.position}
                          comment={highlight.comment}
                        />
                      ) : (
                        <AreaHighlight
                          isScrolledTo={isScrolledTo}
                          highlight={highlight}
                          onChange={(boundingRect) => {
                            updateHighlight(
                              highlight.id,
                              {
                                boundingRect: viewportToScaled(boundingRect),
                              },
                              {image: screenshot(boundingRect)},
                            );
                          }}
                        />
                      );

                      return (
                        <Popup
                          popupContent={<HighlightPopup {...highlight} />}
                          onMouseOver={() => {
                          }}
                          onMouseOut={hideTip}
                          key={index}
                        >
                          {component}
                        </Popup>
                      );
                    }}
                    highlights={[...getHighlightsInDocument(), foundMatchHighlights].flat()}
                  />
                </div>
              );
            }}
          </PdfLoader>
        </div>
      </ListDetails>
    );
  },
);


function SpinnerWithCallback(props: { onUnmount: any }) {
  let {onUnmount} = props;
  useEffect(() => {
    return () => {
      // This function will be called when the component unmounts
      onUnmount();
    };
  }, [onUnmount]);
  return <Spinner />;
}


export default HighlightDocContent;
