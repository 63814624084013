// useFullscreen.ts
import { useState, useRef, RefObject } from "react";

interface UseFullscreenReturn {
  /** Indicates whether the container is in full-screen mode */
  isFullScreen: boolean;
  /** Function to toggle full-screen mode */
  toggleFullScreen: () => void;
  /** Ref object to attach to the container element */
  containerRef: RefObject<HTMLDivElement>;
}

/**
 * A custom React hook that provides full-screen functionality for a container element.
 * It returns a ref to be attached to the container, a boolean indicating if full-screen
 * mode is active, and a function to toggle full-screen mode.
 *
 * @returns {UseFullscreenReturn} An object containing:
 * - `isFullScreen`: A boolean indicating if full-screen mode is active.
 * - `toggleFullScreen`: A function to toggle full-screen mode on/off.
 * - `containerRef`: A ref object to be attached to the container element.
 */

export const useFullscreen = (): UseFullscreenReturn => {
  const [isFullScreen, setIsFullScreen] = useState<boolean>(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const toggleFullScreen = () => {
    if (!document.fullscreenElement) {
      // Enter full-screen mode
      if (containerRef.current) {
        containerRef.current
          .requestFullscreen()
          .then(() => {
            setIsFullScreen(true);
          })
          .catch((err: any) => {
            console.error(
              `Error attempting to enable full-screen mode: ${err.message} (${err.name})`,
            );
          });
      }
    } else {
      // Exit full-screen mode
      document.exitFullscreen().then(() => {
        setIsFullScreen(false);
      });
    }
  };

  return {
    isFullScreen,
    toggleFullScreen,
    containerRef,
  };
};
