import { useEffect, useState } from "react";
import { soCalBounds } from "../../../config/mapConfig";

/**
 * useAutocompletePredictions
 * Custom hook that retrieves autocomplete predictions based on the user’s input and updates as the input changes.
 *
 * @param inputValue - The user’s search input for which autocomplete predictions are fetched.
 * @param autocompleteService - A ref to an initialized `AutocompleteService` instance, used to fetch predictions.
 *
 * @returns An array of autocomplete predictions (`AutocompletePrediction[]`) that match the user's input.
 *
 * @remarks
 * - Fetches predictions when `inputValue` changes and has at least one character.
 * - If no predictions are available, returns an empty array.
 * - The predictions update dynamically based on the `inputValue` and the `AutocompleteService`.
 */

export function useAutocompletePredictions(
  inputValue: string,
  autocompleteService: React.MutableRefObject<google.maps.places.AutocompleteService | null>,
) {
  const [predictions, setPredictions] = useState<
    google.maps.places.AutocompletePrediction[]
  >([]);

  useEffect(() => {
    if (inputValue.length > 0 && autocompleteService.current) {
      const request = {
        input: inputValue,
        locationRestriction: soCalBounds,
      };

      autocompleteService.current.getPlacePredictions(
        request,
        (predictions) => {
          if (predictions) {
            setPredictions(predictions);
          } else {
            setPredictions([]);
          }
        },
      );
    } else {
      setPredictions([]);
    }
  }, [inputValue, autocompleteService]);

  return predictions;
}
