import { useState } from "react";
import FixedPositionWrapper from "../../../../components/ui/FixedPositionWrapper/FixedPositionWrapper";
import Popover from "../../../../components/ui/Popover/Popover";
import useShowFixedSideNav from "./useShowFixedSideNav";
import SideNav from "../../../../components/ui/SideNav/SideNav";
import { AddressSearchInfo } from "../../../../helpers/constants";
import { twMerge } from "tailwind-merge";
import { SectionInfoType } from "../../../../global/types";

type Props = {
  sections: SectionInfoType[];
};

export default function FixedSideNavPopover({ sections }: Props) {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const showFixedSideNav = useShowFixedSideNav(AddressSearchInfo.id);
  const togglePopover = () => setIsPopoverOpen((prev) => !prev);

  return (
    <FixedPositionWrapper
      className={twMerge(`hidden`, showFixedSideNav && "block lg:hidden")}
      placement="top-left"
    >
      <Popover
        isOpen={isPopoverOpen}
        togglePopover={togglePopover}
        placement="bottom-right"
        width={200}
      >
        <SideNav onClick={togglePopover} sections={sections} />
      </Popover>
    </FixedPositionWrapper>
  );
}
