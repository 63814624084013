import React from "react";
import {
  AnswerToUserQuestionInfo,
  PlanningAndZoningInfo,
  JurisdictionInfo,
  PermittingTimelineInfo,
  RelevantMotionsInfo,
} from "../../../helpers/constants";
import { useAppSelector } from "../../../app/hooks";
import { SectionInfoType } from "../../../global/types";


// hook that compiles array for sidenav based on render logic of sections

export default function useFilterSideNavSections() {
  const {
    endpointOutputs: { siteInfoResponse, userQueryAnswer, permitTimeline },
    isLoading,
  } = useAppSelector((state) => state.addressSearch);

  const jurisdiction = siteInfoResponse
    ? siteInfoResponse.jurisdiction
    : undefined;

  const sideNavSections: SectionInfoType[] = [];

  if (isLoading) return [];

  // Apply conditions in the order of the imported IDs
  if (userQueryAnswer?.length) sideNavSections.push(AnswerToUserQuestionInfo);

  if (siteInfoResponse) sideNavSections.push(PlanningAndZoningInfo);

  if (siteInfoResponse && jurisdiction) sideNavSections.push(JurisdictionInfo);

  if (permitTimeline && siteInfoResponse && permitTimeline?.building_median)
    sideNavSections.push(PermittingTimelineInfo);

  if (siteInfoResponse?.council_district)
    sideNavSections.push(RelevantMotionsInfo);

  // Return the filtered array
  return sideNavSections;
}
