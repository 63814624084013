import React, { useState, useRef } from "react";
import { twMerge } from "tailwind-merge";
import ListButton from "../../ui/Buttons/ListButton";
import { ListSectionType } from "../../ui/ListDetails/ListDetails";
import useClickOutside from "../../../hooks/useClickOutside";
import { Triangle16Filled } from "@fluentui/react-icons";

type DropdownProps = {
  list: ListSectionType[];
  selectedIndex: string;
  dropdownHeight?: number;
  handleSetIndex: (index: string) => void;
  isLoading?: boolean;
};

export default function Dropdown({
  list,
  selectedIndex,
  handleSetIndex,
  dropdownHeight = 600,
  isLoading,
}: DropdownProps) {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const indexArray = selectedIndex.split("-");
  const sectionIndex = parseInt(indexArray[0]) ?? 0;
  const itemsIndex = parseInt(indexArray[1]) ?? 0;

  const selectedItem = list.length > 0 && list[sectionIndex].items[itemsIndex];

  // Close dropdown on outside click
  useClickOutside(dropdownRef, () => {
    setIsOpen(false);
  });

  if (!list || !selectedItem)
    return (
      <div
        className={twMerge(
          "body-font font-n flex h-10 w-full flex-col items-center justify-center bg-rf-light-beige shadow-sm ring-1 ring-inset ring-rf-light-beige",
        )}
      >
        <p>No options found</p>
      </div>
    );

  return (
    <div className="relative" ref={dropdownRef}>
      <div
        className={twMerge(
          "relative block w-full shadow-sm ring-1 ring-inset ring-rf-light-beige",
        )}
        onClick={() => setIsOpen(!isOpen)}
      >
        <ListButton
          isLoading={isLoading}
          handleSetIndex={handleSetIndex}
          index={selectedIndex}
          selectedIndex={selectedIndex}
          {...selectedItem}
        />

        <div
          className={`absolute right-3 top-[50%] translate-y-[-50%] cursor-pointer text-rf-dark-brown duration-300 ${isOpen ? "rotate-180" : "rotate-270"}`}
        >
          <Triangle16Filled />
        </div>
      </div>

      {isOpen && (
        <div
          style={{ maxHeight: dropdownHeight }}
          className="thin-scrollbar absolute right-0 z-50 w-full origin-top overflow-y-auto bg-rf-very-light-gray shadow-lg ring-rf-light-beige focus:outline-none"
          role="menu"
          aria-orientation="vertical"
        >
          {list.map((section, i) => (
            <div key={i}>
              {section.title && (
                <>
                  <h3 className="body-font my-3 mt-3.5 line-clamp-1 overflow-hidden break-all px-3 font-semibold md:px-[18px] lg:px-6">
                    {section.title}
                  </h3>
                  <hr className="line-break my-0" />
                </>
              )}
              {section.items.map((item, index) => (
                <div key={`${i}-${index}`} onClick={() => setIsOpen(!isOpen)}>
                  <ListButton
                    isLoading={isLoading}
                    handleSetIndex={handleSetIndex}
                    index={`${i}-${index}`}
                    selectedIndex={selectedIndex}
                    {...item}
                  />
                </div>
              ))}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
