/**
 * Filters out objects with undefined values and invalid key-value pairs.
 * Invalid values include empty strings, null, undefined, or empty arrays.
 * Returns only objects with valid key-value pairs.
 */

import { KeyValueContentType } from "../components/ui/KeyValueSection/KeyValueSection";

export type UnfilteredKeyValueContentType = Array<{
  [key: string]: string | string[] | null | undefined;
}>;

export function filterValidContent(
  content: UnfilteredKeyValueContentType,
): KeyValueContentType {
  return content
    .filter((item) => item && typeof item === "object") // Filter out undefined or non-object items
    .map((item) => {
      // Reduce to a new object that contains only valid string or string[] values
      const filteredItem = Object.entries(item).reduce(
        (acc, [key, value]) => {
          if (
            value !== "" && // Filter out empty strings
            value !== null && // Filter out null
            value !== undefined && // Filter out undefined
            (!Array.isArray(value) || value.length > 0) // Filter out empty arrays
          ) {
            acc[key] = value as string | string[]; // Cast valid values to string | string[]
          }
          return acc;
        },
        {} as { [key: string]: string | string[] }, // Ensure only string or string[] types are allowed
      );

      // Only return the object if it has valid key-value pairs
      return Object.keys(filteredItem).length > 0 ? filteredItem : undefined;
    })
    .filter(
      (item): item is { [key: string]: string | string[] } =>
        item !== undefined,
    ); // Filter out undefined items and enforce type
}
