import React, { useState } from "react";
import { twMerge } from "tailwind-merge";
import { PanelLeftText24Regular } from "@fluentui/react-icons";
import { COLORS } from "../../../helpers/constants";
import { toggleVerticalBodyScroll } from "../../../helpers/toggleVerticalBodyScroll";
import { buttonClassNames } from "@fluentui/react-components";

type Props = {
  onClose?: () => void;
  children: React.ReactNode;
  side?: "left" | "right";
};

export default function DrawerFullScreen({
  onClose,
  children,
  side = "left",
}: Props) {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    if (onClose) onClose();
    setIsDrawerOpen((prev) => !prev);

    toggleVerticalBodyScroll(!isDrawerOpen);
  };

  const toggleBtn = (
    <button
      className={twMerge("flex h-[40px] w-[40px] items-center justify-center")}
      onClick={toggleDrawer}
    >
      <PanelLeftText24Regular primaryFill={`white`} />
    </button>
  );

  return (
    <div className="relative">
      {/* Trigger Button */}
      {!isDrawerOpen && (
        <div className="m-1.5 rounded-full bg-rf-light-gray">{toggleBtn}</div>
      )}

      {/* Full-screen Drawer */}
      <div
        className={twMerge(
          `fixed bottom-0 top-0 z-40 h-[calc(100vh)] w-full bg-rf-very-dark-gray transition-transform duration-300 ease-in-out`,
          side === "right" ? "right-0" : "left-0",
          isDrawerOpen
            ? "translate-x-0"
            : side === "right"
              ? "translate-x-full"
              : "-translate-x-full",
        )}
      >
        {/* Close Button */}
        <div
          className={twMerge(
            "flex justify-end p-4 ",
            side === "right" ? "justify-start" : "justify-end",
          )}
        >
          {toggleBtn}
        </div>

        {/* Drawer Content */}
        <div className="p-5">{children}</div>
      </div>
    </div>
  );
}
