import React, { useEffect, useRef, useState } from "react";

import {
  ArrowDownload24Regular,
  ExpandUpRight24Regular,
  ContractDownLeft24Regular,
  ArrowUp16Filled,
  ArrowDown16Filled,
} from "@fluentui/react-icons";
import { twMerge } from "tailwind-merge";

type Props = {
  url: string;
  isFullScreen: boolean;
  toggleFullScreen: () => void;
  onSearchInputChanged: (searchText: string, index: number) => void;
  numSearchResults: number;
};

export default function InteractionBar({
  url,
  isFullScreen,
  toggleFullScreen,
  onSearchInputChanged,
  numSearchResults = 0,
}: Props) {
  let [searchText, setSearchText] = useState("");
  let [searchIndex, setSearchIndex] = useState(0);

  const searchInputRef = useRef<HTMLInputElement>(null);

  const containerRef = useRef<HTMLDivElement>(null);
  const [isVisible, setIsVisible] = useState(false);

  // Ordering is important. Set index to 0 before callback.
  useEffect(() => {
    setSearchIndex(0);
  }, [searchText]);

  useEffect(() => {
    onSearchInputChanged(searchText, searchIndex);
  }, [searchText, searchIndex]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      { threshold: 0.1 },
    );

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, []);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (
        isVisible &&
        ((event.ctrlKey && event.key === "f") ||
          (event.metaKey && event.key === "f"))
      ) {
        event.preventDefault();
        searchInputRef.current?.focus();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [isVisible]);

  return (
    <div
      ref={containerRef}
      className="absolute left-0 top-0 z-10 w-[calc(100%-10px)] bg-white @container"
    >
      <div className="block h-fit justify-between @md:flex">
        <div className="relative flex h-10 items-center">
          <input
            ref={searchInputRef}
            type="text"
            placeholder="Search..."
            className="body-font z-10 h-full w-full border-[1px] border-rf-light-gray px-4 py-2 focus:outline-none @md:w-auto"
            onChange={(event) => setSearchText(event.target.value)}
            onKeyDown={(event) =>
              event.key === "Enter" &&
              setSearchIndex(searchIndex + (event.shiftKey ? -1 : 1))
            }
          />
          <p className={"citation-font shrink-0 p-1 px-2"}>
            {numSearchResults === 0 && <>0 / 0</>}
            {numSearchResults > 0 && (
              <>
                {(((searchIndex % numSearchResults) + numSearchResults) %
                  numSearchResults) +
                  1}{" "}
                / {numSearchResults}
              </>
            )}
          </p>

          <button
            onClick={() =>
              numSearchResults > 0 && setSearchIndex(searchIndex - 1)
            }
            disabled={numSearchResults === 0}
            className={twMerge(
              "block h-full shrink-0 px-3 duration-300",
              numSearchResults === 0
                ? "cursor-not-allowed opacity-50"
                : "hover:bg-rf-very-light-gray active:bg-rf-light-gray",
            )}
          >
            <ArrowUp16Filled />
          </button>

          <button
            onClick={() =>
              numSearchResults > 0 && setSearchIndex(searchIndex + 1)
            }
            disabled={numSearchResults === 0}
            className={twMerge(
              "block h-full shrink-0 px-3 duration-300",
              numSearchResults === 0
                ? "cursor-not-allowed opacity-50"
                : "hover:bg-rf-very-light-gray active:bg-rf-light-gray",
            )}
          >
            <ArrowDown16Filled />
          </button>

          <hr className="divider absolute bottom-0 w-full" />
        </div>
        <div className="flex h-10">
          <button
            onClick={toggleFullScreen}
            className="block h-full shrink-0 px-4 duration-300 hover:bg-rf-very-light-gray active:bg-rf-light-gray"
          >
            {!isFullScreen ? (
              <ExpandUpRight24Regular />
            ) : (
              <ContractDownLeft24Regular />
            )}
          </button>
          <a
            href={url}
            target="_blank"
            className="flex h-full shrink-0 items-center px-4 duration-300 hover:bg-rf-very-light-gray active:bg-rf-light-gray"
          >
            <ArrowDownload24Regular />
          </a>
        </div>
      </div>
      <hr className="divider absolute bottom-0 w-full" />
    </div>
  );
}
