import React from "react";
import { ListItemType } from "../ListDetails/ListDetails";

type Props = ListItemType & {
  selectedIndex: string;
  index: string;
  handleSetIndex: (index: string) => void;
  isLoading?: boolean;
};

export default function ListButton({
  icon,
  desc,
  citation,
  counter,
  index,
  selectedIndex,
  isLoading,
  onClick,
  handleSetIndex,
}: Props) {
  const isSelected = selectedIndex === index;

  return (
    <button
      disabled={isLoading}
      onClick={() => {
        handleSetIndex(index);
        onClick && onClick();
      }}
      className={`${isSelected && "bg-rf-light-gray"} listItemPadding flex w-full justify-start gap-2 border-b-[2px] border-rf-light-gray text-start duration-300 hover:bg-rf-light-gray`}
    >
      <div className="relative">
        <p className={"desc-font break-word line-clamp-2"}>{desc}</p>

        {citation && (
          <p
            className={`citation-font mt-1.5 line-clamp-1 break-all underline lg:mt-3`}
          >
            {citation}
          </p>
        )}

        {isSelected && counter && (
          <p className={"citation-font absolute line-clamp-1 break-all"}>
            {counter}
          </p>
        )}
      </div>
    </button>
  );
}
